import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { PanelV } from 'react-panel-rs';
import { TextV } from 'react-native-text-vfw';
import { IconV } from 'react-icon-rs';
import { useViewport } from 'react-viewport-provider';
import { titleCase } from 'title-case';
import { Card } from '@material-ui/core';
import { AvatarV } from 'react-native-avatar-vfw';
import { IconV as NativeIcon } from 'react-native-icon-vfw';
import { Path, Svg } from 'react-native-svg';
import Rating from '@material-ui/lab/Rating';
import { ThemeContext } from '../../../../providers/theme';


interface HeaderInfoProps {
  title?: string;
  volume?: string;
  info?: string;
  price?: string;
  handleLGHeaderInfoClick?(): void;
  onInfoClick?(): void;
  onRating?(value: number): void;
};

export const HeaderInfoLG = ({
  title,
  volume,
  info,
  price,
  handleLGHeaderInfoClick,
  onInfoClick,
  onRating
}: HeaderInfoProps) => {
  const { height, width } = useViewport();
  const theme = React.useContext(ThemeContext);
  const [rate, setRate] = React.useState(4);

  const styles = {
    titleStyle: {
      display:'block',
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: 35,
      letterSpacing: 0.95,
      paddingVertical: 5,
    },
    volumeStyle: {
      color: 'gray',
      fontSize: 18,
      fontWeight: 'bold',
      paddingVertical: 5,
      marginTop: 30,
      // paddingLeft: 30
    },
    infoStyle: {
      textAlign: 'left',
      color: 'gray',
      fontSize: 19,
      fontWeight: '600',
      paddingTop: 15,
      letterSpacing: 0.95,
      lineHeight: '200%'
      // paddingLeft: 30,
    },
    priceContainer: {
      backgroundColor: theme?.palette?.primary,
      width: price
        ? price.length * 15
        : 90,
      borderRadius: 10,
      elevation: 10,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
      // marginLeft: 30
    },
    priceStyle: {
      color: '#fff',
      fontWeight: 'bold',
      letterSpacing: 0.9,
    },
    svgStyle: {
      width: '100%',
    },
    panelStyle: {
      borderRadius: 10,
      alignItems: 'center',
      backgroundColor: 'transparent',
      // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      // padding: -20
    },
    containerStyle: {
      width: '80%',
      minHeight: '400px',
      marginTop: 5,
      paddingLeft: 10,
    }
  };

  const _getIcon = () => {
    if (true) {
      return (
        <IconV
          icons={[
            {
              name: 'lock',
              style: {
                color: 'green',
              },
              size: '2x',
            },
          ]}
        />
      );
    } else {
      return (
        <IconV
          icons={[
            {
              name: 'unlock-alt',
              style: {
                color: 'tomato',
              },
              size: '2x',
            },
          ]}
        />
      );
    }
  };

  return (
    // <>
    //   <Svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     viewBox="0 0 1440 320"
    //     style={styles.svgStyle}
    //   >
    //     <Path
    //       fill={theme?.palette?.primary}
    //       fill-opacity="1"
    //       d="M0,320L60,314.7C120,309,240,299,360,277.3C480,256,600,224,720,192C840,160,960,128,1080,133.3C1200,139,1320,181,1380,202.7L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
    //     ></Path>
    //     <Path
    //       fill={theme?.palette?.secondary}
    //       fill-opacity="1"
    //       d="M0,224L60,234.7C120,245,240,267,360,250.7C480,235,600,181,720,138.7C840,96,960,64,1080,85.3C1200,107,1320,181,1380,218.7L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
    //     ></Path>
    //   </Svg>
    <View style={StyleSheet.flatten([styles.containerStyle])}>
    <View style={StyleSheet.flatten([styles.panelStyle])} onClick={handleLGHeaderInfoClick}>
      <FBGridV>
        {/* <View onClick={handleLGHeaderInfoClick}> */}
        <FBItemV colspan={24}>
          <FBGridV justify={'center'}>
            <FBItemV colspan={24} style={{ paddingBottom: '15px' }}>
              {title && (
                <View style={{paddingTop: 20}}>
                <TextV
                  type={'title3'}
                  children={typeof title === 'string' && titleCase(title)}
                  style={StyleSheet.flatten([styles.titleStyle])}
                  ellipsizeMode={'tail'}
                  numberOfLines={1}
                />
              </View>
              )}
              
            </FBItemV>
            {/* <FBItemV colspan={2}>{_getIcon()}</FBItemV> */}
            {/* <FBItemV colspan={24} style={{marginBottom: 10}}>
              <Rating
              name="simple-controlled"
                value={rate}
              onChange={(event: object, value: number) => {
                onRating(value)
                setRate(value)}}
              />
            </FBItemV> */}
            <FBItemV colspan={24} style={{ paddingBottom: '5px' }}>
              {volume && (
                <TextV
                  type={'callout'}
                  children={typeof volume === 'string' && titleCase(volume)}
                  ellipsizeMode={'tail'}
                  numberOfLines={1}
                  style={StyleSheet.flatten([styles.volumeStyle])}
                />
              )}
            </FBItemV>
            <FBItemV colspan={24}>
              {price && (
                <View style={StyleSheet.flatten([styles.priceContainer])}>
                  <TextV
                    type={'title3'}
                    children={typeof price === 'string' && titleCase(price)}
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={StyleSheet.flatten([styles.priceStyle])}
                  />
                </View>
              )}
            </FBItemV>
            <FBItemV colspan={24}>
              {info && (
                <View style={{}}>
                  <TextV
                    type={'headline'}
                    children={typeof info === 'string' && titleCase(info)}
                    ellipsizeMode={'tail'}
                    numberOfLines={3}
                    style={StyleSheet.flatten([styles.infoStyle])}
                  />
                </View>
              )}
            </FBItemV>
          </FBGridV>
        </FBItemV>
      {/* </View> */}
      <FBItemV colspan={24}>
        {/* <View style={{ position: 'absolute', top: 300, left: 540 }}> */}
        {/* </View> */}
        </FBItemV>
      </FBGridV>
    </View>
      <NativeIcon
        name={'info'}
        type={'entypo'}
        size={30}
        color={theme?.palette?.secondary}
        onPress={onInfoClick}
        containerStyle={{ alignItems: 'flex-end', marginRight: '5%', marginTop: '5%' }}
      />
    </View>
    //   <Svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     viewBox="0 0 1440 320"
    //     style={styles.svgStyle}
    //   >
    //     <Path
    //       fill={theme?.palette?.secondary}
    //       fill-opacity="1"
    //       d="M0,192L60,160C120,128,240,64,360,37.3C480,11,600,21,720,53.3C840,85,960,139,1080,149.3C1200,160,1320,128,1380,112L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
    //     ></Path>
    //     <Path
    //       fill={theme?.palette?.primary}
    //       fill-opacity="1"
    //       d="M0,256L60,218.7C120,181,240,107,360,96C480,85,600,139,720,176C840,213,960,235,1080,213.3C1200,192,1320,128,1380,96L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
    //     ></Path>
    //   </Svg>
    // </>
  );
};