import gql from 'graphql-tag';

export const GET_PROV_HOME_PAGE_DATA = gql`
  query($hash: String!, $network: String){
    getFullProvenanceBarcodeWeb(hash: $hash, network: $network){
      id
      isValid
      BatchPromoDetails{
      isPromoEnabled
      promoVideoUrl
      isPromoWebsiteUrlEnabled
      promoWebsiteUrl
      contestButtonText
      promoButtonText
    }
      Spinner {
        loadingText
      }
      FAB {
        iconName
        iconType
        iconSize
      }
      ProductInfoCard {
        title
        info
        subTitle
        price
      }
      ProductImageCard {
        productImages {
          image
        }
      }

      chipList {
        chipItems {
          key
          imageUri
          docUri
          title
        }
      }

      ProvenanceMapView {
        post {
          latitude
          longitude
        }
        initialRegion {
          latitude
          longitude
          latitudeDelta
          longitudeDelta
        }
        loadingEnabled
        loadingIndicatorColor
        showsUserLocation
        userLocationAnnotationTitle
        geolocationOptions {
          enableHighAccuracy
          timeout
          maximumAge
        }
        polylineStrokeWidth
        polylineStrokeColor
        isCustomMarkerToDisplay
        polylinePoints
        latitudeDelta
        longitudeDelta
        listMarkers {
          key
          latitude
          longitude
          latitudeDelta
          longitudeDelta
          mapViewCallOutText1
          mapViewCallOutText2
          mapViewCallOutText2ImageURL
          mapViewCallOutText3
          mapViewCallOutText3ImageURL
          mapViewCallOutReadMoreText
          pinColor
          title
          imageURL {
            uri
          }
        }
      }
      CarouselListing {
        markers {
          key
          title
          imageURL {
            uri
          }
          latitude
          longitude
          latitudeDelta
          longitudeDelta
        }
        searchMarkers {
          title
        }
      }

      ModalProductDetails {
        organizationLogo{uri}
        productName
        productInfo
        productDetailTitle
        productDetails {
          key
          prodKey
          prodValue
        }
        companyDetails {
          key
          compKey
          compValue
        }
        companyDetailTitle
      }
      ModalProductInformation {
        headerTitle
        productInfoList {
          key
          title
          content {
            key
            name
            subInfo
            quantity
          }
          description
        }
      }
      provenanceTimeline {
        timeLineData {
          key
          title
          location
          date
          organization
          organizationLogo {
            uri
          }
          imageUrl
          icon {
            uri
          }
          galleryImages {
            uri
          }
          latitude
          longitude
          latitudeDelta
          longitudeDelta
          content
          rightImageURL {
            uri
          }
          boxInfo {
            key
            label
            value
            url
            width
            height
            location {
              latitude
              longitude
            }
            path {
              d
            }
          }
        }
      }
      ModalFeedbackForm {
        title
        prodImage {
          uri
        }
        prodTitle
        prodSubTitle
        prodPrice
        formFields {
          type
          name
          required
          errorMessage
          options {
            inputContainerStyle {
              borderColor
              marginVertical
              borderRadius
              height
              width
              borderWidth
              alignSelf
              paddingHorizontal
              backgroundColor
            }
            multiline
            inputStyle {
              color
              height
              width
              paddingHorizontal
              borderRadius
              fontSize
            }
            errorStyle {
              paddingLeft
            }
            placeholder
            placeholderTextColor
            keyboardType
            returnKeyType
          }
        }
      }
    }
  }
`;