//@ts-nocheck
import React, {useState, useContext} from 'react';
import {AvatarV} from 'react-avatar-rs';
import {BadgeV} from 'react-badge-rs';
import {StyleSheet, Text, View} from 'react-native';
// import {NavV} from 'react-nav-rs';
import {NavbarV} from 'react-navbar-rs';
import {useViewport} from 'react-viewport-provider';
import Divider from 'rsuite/lib/Divider';
import Dropdown from 'rsuite/lib/Dropdown';
import Header from 'rsuite/lib/Header';
import Icon from 'rsuite/lib/Icon';
import Nav from 'rsuite/lib/Nav';
import {ThemeContext} from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';

const BP = blueprint.HeaderV;

type IconPropsInternal = {
  style?: any;
  name: string | any;
  stack?: '1x' | '2x';
  componentClass?: any;
  fixedWidth?: boolean;
  flip?: 'horizontal' | 'vertical';
  inverse?: boolean;
  pulse?: boolean;
  rotate?: boolean;
  size?: 'lg' | '2x' | '3x' | '4x' | '5x';
  spin?: boolean;
  svgStyle?: any;
  iconStyle?: any;
};

type NavBarItems = {
  icon?: IconPropsInternal;
  appearance?: 'default' | 'drop-down' | 'avatar';
  eventKey?: any;
  badge?: boolean | string | number;
  title?: string;
  dropdownItems?: {
    eventKey?: any;
    title?: string;
    divider?: boolean;
  }[];
  avatar?: {
    src?: string;
    title?: string;
  };
};

interface HeaderVProps {
  onNavItemSelect?(activeKey: any): void;
  navBarRightItems?: NavBarItems[];
  navbarStyle?: object;
  headerLogo?: string;
  headerLogoStyle?: object;
  headerTitle?: string;
  headerTitleStyle?: object;
}

const ids = require('short-id');
const NAV_BAR_BG_COLOR = '#F4F400';
const RIGHT_AVATAR_BG_COLOR = '#FFFFFF';
// const TITLE_COLOR = '#717171';
const TITLE_COLOR = '#fff';
const NAV_BAR_HEIGHT = 66;

export const HeaderV = (props: HeaderVProps) => {
  const theme = useContext(ThemeContext);
  const [drawer, setDrawer] = React.useState<any>({show: false});
  const toggleDrawer = (placement: string) => {
    const _drawer = {...drawer};
    _drawer.show = true;
    setDrawer({
      ..._drawer,
    });
  };
  const {bp} = useViewport();
  const [activeKey, setActiveKey] = useState<any>(null);
  const styles = {
    navbarStyle: {
      // backgroundColor: theme?.palette.primary ?? NAV_BAR_BG_COLOR,
      backgroundColor: 'transparent',
      boxShadow: '1px 1px #C1C1C1',
    },
    avatarStyle: {
      // background: 'transparent',
      width: 56,
      height: 56,
      marginRight: 10,
      marginLeft: 10,
      marginBottom: 5,
      marginTop: 5,
      backgroundColor: '#fff',
    },
    headerStyle: {},
    headerTitleContainerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      height: NAV_BAR_HEIGHT,
    },
    headerTitleStyle: {
      color: TITLE_COLOR,
      fontWeight: 'bold',
      fontSize: 22,
    },
    rightAvatarStyle: {
      background: RIGHT_AVATAR_BG_COLOR,
      marginTop: 12,
      color: TITLE_COLOR,
    },
    navItemTitleStyle: {
      color: TITLE_COLOR,
      fontWeight: '500',
    },
    navbarItemIconStyle: {
      color: TITLE_COLOR,
    },
  };

  const renderIcon = (
    icon: IconPropsInternal | undefined,
    eventKey: any,
    badge?: boolean | string | number,
  ) => {
    if (icon) {
      if (badge) {
        return (
          <BadgeV content={badge}>
            <Icon
              {...icon}
              icon={icon?.name}
              style={StyleSheet.flatten([
                styles.navbarItemIconStyle,
                icon.style,
                // activeKey === eventKey &&
                //   props.primaryColor && {color: props.primaryColor},
              ])}
            />
          </BadgeV>
        );
      }
      return (
        <Icon
          {...icon}
          icon={icon?.name}
          style={StyleSheet.flatten([
            styles.navbarItemIconStyle,
            icon.style,
            // activeKey === eventKey &&
            //   props.primaryColor && {color: props.primaryColor},
          ])}
        />
      );
    }
    return null;
  };

  const renderDropDownItems = (items?: any[]) => {
    const _items = [];
    if (items && Array.isArray(items)) {
      for (let i = 0; i < items.length; ++i) {
        _items.push(
          <Dropdown.Item key={ids.generate()} eventKey={items[i].eventKey}>
            {items[i].title && (
              <Text
                style={StyleSheet.flatten([
                  styles.navItemTitleStyle,
                  activeKey === items[i].eventKey &&
                    theme?.palette.primary && {
                      color: theme?.palette.primary,
                    },
                ])}
              >
                {items[i].title}
              </Text>
            )}
            {items[i].divider && (
              <View style={{marginTop: 15}}>
                <Divider style={{padding: 0, margin: 0}} />
              </View>
            )}
          </Dropdown.Item>,
        );
      }
    }

    return _items;
  };

  const handleNavRightItemSelect = (activeKey: any) => {
    setActiveKey(activeKey);
    props.onNavItemSelect && props.onNavItemSelect(activeKey);
  };

  const renderNavBarRightItems = () => {
    const _items = [];
    const _navBarRightItems = props.navBarRightItems
      ? props.navBarRightItems
      : BP.navBarRightItems;
    if (_navBarRightItems && Array.isArray(_navBarRightItems)) {
      for (let i = 0; i < _navBarRightItems.length; ++i) {
        const _item = _navBarRightItems[i];
        if (_item.appearance === 'avatar') {
          _items.push(
            <AvatarV
              key={ids.generate()}
              circle={true}
              src={_item.avatar?.src}
              alt={_item.avatar?.title}
              size={'sm'}
              style={StyleSheet.flatten([styles.rightAvatarStyle])}
              onClick={() =>
                props.onNavItemSelect && props.onNavItemSelect(_item.eventKey)
              }
            >
              {_item.avatar?.title}
            </AvatarV>,
          );
        }
        if (_item.appearance === 'drop-down') {
          _items.push(
            <Dropdown key={ids.generate()} title={_item.title}>
              {renderDropDownItems(_item.dropdownItems)}
            </Dropdown>,
          );
        } else {
          _items.push(
            <Nav.Item
              key={ids.generate()}
              eventKey={_item.eventKey}
              icon={renderIcon(_item.icon, _item.eventKey, _item.badge)}
            >
              {_item.title && (
                <Text
                  style={StyleSheet.flatten([
                    styles.navItemTitleStyle,
                    // activeKey === _item.eventKey &&
                    //   props.primaryColor && {
                    //     color: props.primaryColor,
                    //   },
                  ])}
                >
                  {_item.title}
                </Text>
              )}
            </Nav.Item>,
          );
        }
      }
    }
    return _items;
  };
  return (
    <Header style={StyleSheet.flatten([styles.headerStyle])}>
      <NavbarV
        navbarStyle={StyleSheet.flatten([
          styles.navbarStyle,
          props.navbarStyle,
        ])}
        // renderNavbarHeader={() => (
        //   <AvatarV
        //     key={ids.generate()}
        //     circle={true}
        //     src={props.headerLogo ?? BP.headerLogo}
        //     size={'lg'}
        //     avatarStyle={StyleSheet.flatten([
        //       styles.avatarStyle,
        //       props.headerLogoStyle,
        //     ])}
        //   />
        // )}
        renderNavBody={() => (
          <React.Fragment>
            <Nav>
              {bp !== 'xs' &&
                bp !== 'sm' &&
                (props.headerTitle ?? BP.headerTitle) && (
                  <View
                    style={StyleSheet.flatten([
                      styles.headerTitleContainerStyle,
                    ])}
                  >
                    <Text
                      style={StyleSheet.flatten([
                        styles.headerTitleStyle,
                        props.headerTitleStyle,
                      ])}
                    >
                      {props.headerTitle ?? BP.headerTitle}
                    </Text>
                  </View>
                )}
            </Nav>
            {/* <Nav
              pullRight
              activeKey={activeKey}
              onSelect={handleNavRightItemSelect}
            >
              {renderNavBarRightItems()}
            </Nav> */}
          </React.Fragment>
        )}
      ></NavbarV>
    </Header>
  );
};

// const _renderNavbarHeader = () => {
//   const _header = [];
//   if (props.headerLogo) {
//     _header.push(
//       <AvatarV
//         children={<Text>Hello</Text>}
//         key={ids.generate()}
//         circle={true}
//         src={props.headerLogo}
//         size={'lg'}
//         avatarStyle={StyleSheet.flatten([
//           styles.avatarStyle,
//           props.headerLogoStyle,
//         ])}
//       />,
//     );
//   }
//   return _header;
// };

// const _renderBody = () => {
//   const _body = [];
//   _body.push(
//     <NavV>
//       {props.headerTitle && (
//         <View style={StyleSheet.flatten([styles.headerTitleContainerStyle])}>
//           <Text
//             style={StyleSheet.flatten([
//               styles.headerTitleStyle,
//               props.headerTitleStyle,
//             ])}
//           >
//             {props.headerTitle}
//           </Text>
//         </View>
//       )}
//     </NavV>,
//   );
//   return _body;
// };
