import React, { useContext } from 'react';
import { ThemeContext } from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';
import { View, Text, StyleSheet, ImageBackground } from 'react-native';
import { AvatarV, AvatarSize } from 'react-native-avatar-vfw';
import { ButtonV } from 'react-button-rs';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { EventBus } from 'event-bus-vfs';
import { FORM_VALUES, defaultValuesDS, FormV } from 'react-form-rs';
import { IProps } from './index';
import Alert from 'rsuite/lib/Alert';
import { Panel } from 'rsuite';
import { BACKGROUND_IMAGE } from '../../../services/loadAssets';

let formContext = {};
const BP = blueprint.LoginPage;

export const LoginPageLG = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const { width, height } = useViewport();
  const history = useHistory();
  const [date, setDate] = React.useState<Date>(new Date());
  const [validateForm, setValidateForm] = React.useState<any>(null);
  React.useEffect(() => {
    const app = EventBus.on(FORM_VALUES, value => {
      formContext = { ...value };
    });
    return function () {
      app.off();
    };
  }, []);

  const styles = {
    panelStyle: {
      backgroundColor: '#fff',
      width: 500,
      alignItems: 'center',
      justifyContent: 'center',
      margin: '10% auto',
    },
    logContainerStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    titleStyle: {
      paddingLeft: 5,
      fontSize: 24,
      fontWeight: 700
    },
    forgotPasswordStyle: {
      textAlign: 'center',
      color: 'blue'
    }
  }

  const handleValidateResult = (
    formContext: any,
    status: 'success' | 'error',
    validateResult: any,
  ) => {
    //console.log('defaultValuesDS', defaultValuesDS.get());
    if (status === 'success') {
      //  console.log('login data collected successful');
      props.onLogin &&
        props.onLogin(
          defaultValuesDS.get('emailAddress')?.defaultValue,
          defaultValuesDS.get('password')?.defaultValue,
          defaultValuesDS.get('rememberMe')?.defaultValue,
        );
      defaultValuesDS.clear();
    }
    if (status === 'error') {
      BP.loginFFEC && Alert.error(BP.loginFFEC, 10000);
    }
    setDate(new Date());
    setValidateForm(false);
  };

  return (
    <ImageBackground source={BACKGROUND_IMAGE}>
      {/* // <div style={{ width: '100%', height: '100%', backgroundImage:`url("https://i.ibb.co/6ZCvN5R/image.png")`, backgroundRepeat: 'no-repeat', backgroundPosition:'left bottom', backgroundSize:'cover'}}> */}
      <Panel shaded style={StyleSheet.flatten([styles.panelStyle])}>
        <View style={StyleSheet.flatten([styles.logContainerStyle])}>
          <AvatarV
            source={{ uri: BP?.leftAvatar }}
            size={AvatarSize?.Large}
          />
          <Text style={StyleSheet.flatten([styles.titleStyle])}>{BP.title}</Text>
        </View>
        <FormV
          components={BP.formFields}
          validateForm={validateForm}
          formContext={formContext}
          defaultValues={defaultValuesDS.get()}
          onValidateResult={handleValidateResult}

        />
        <ButtonV
          buttonTitle={"Sign In"}
          style={{ backgroundColor: '#e68434' }}
          buttonTitleStyle={{ fontWeight: 'bold', color: '#fff' }}
          containerStyle={{ width: '100%', margin: '0 auto' }}
          onPress={() => {
            setValidateForm({
              ...formContext,
            });
          }}
        />
        <View style={{ justifyContent: 'center', alignItems: 'center', paddingTop: 20 }}>
          <Text style={StyleSheet.flatten([styles.forgotPasswordStyle])} onPress={props.forgotPasswordPressed}>{BP.forgotPasswordText}</Text>
        </View>
      </Panel>
    </ImageBackground>
  );
};
