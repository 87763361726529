import React, { useContext, useLayoutEffect, useEffect, useState } from 'react';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../providers/theme';
import { useViewport } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { Icon, Whisper, Tooltip, Divider, Avatar, Button } from 'rsuite';
import { ButtonV, IconButtonV } from 'react-button-rs';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { CheckPickerV } from 'react-checkpicker-rs';
import { SelectInputV } from 'react-select-input-rs';
import { DatePickerV } from 'react-datepicker-rs';
import Loader from 'rsuite/lib/Loader';
import { FormV, FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import Pagination from '@material-ui/lab/Pagination';

import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { DrawerV } from 'react-drawer-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { StatisticsCard } from './statistics';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { DashboardCardsV } from 'react-dashboard-cards-web';
import { PanelV } from 'react-panel-rs';
import { EventBus } from 'event-bus-vfs';
import Placeholder from 'rsuite/lib/Placeholder';
import defaultConfig from '../../../../../../config/default.config.json';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.counterfeit;
let formContext = {};

type Iprops = {
    counterfeitLoading?: boolean;
    counterfeitData?: any[];
    counterfeitStats?: any[];
    productsAndBatches?: { gtin: string, name?: string, Batches: { batchIdentifier?: string }[] }[];
    counterfeitPageChange?(pageNo?: number, rowsPerPage?: string): void;
    counterfeitFilterApply?(rowsPerPage: string, formData?: any): void;
    scanCities?: any[];
    scanCountries?: any[];
    scanRegions?: any[];

};

const styles = {
    leftcontainerStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    rightContainerStyle: {
        float: 'right',
        display: 'flex',
    },
    tableStyle: {
        margin: '0 auto',
    },
    activeStyle: {
        backgroundColor: 'green',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    InactiveStyle: {
        backgroundColor: 'orange',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    revokedStyle: {
        backgroundColor: 'red',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    paginationStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'cnter',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#fff',
        borderTop: '1px solid #ccc',
        width: '100%'
    },
    placeholderStyle: {
        margin: '0 auto',
        backgroundColor: '#fff',
        padding: 10
    },
    statusStyle: { alignItems: 'center', display: 'flex', flexDirection: 'row' },
    filterStyle: { justifyContent: 'flex-end', display: 'block', float: 'right', margin: 8 }
};
const generateFakeData = async (schema, rows = 1) => {

    let res = [];
    try {
        if (schema) {
            for (let i = 0; i < rows; ++i) {
                let _sample = { ...schema[i] };
                res.push(_sample);
            }
        }
    } catch (err) { }
    // try {
    //   if (schema) {
    //     for (let i = 0; i < rows; ++i) {
    //       const sample = await jsf.resolve(BP?.users);
    //       let _sample = {...sample.user};
    //       _sample.avatar = `https://ui-avatars.com/api/?name=${_sample.name}`;
    //       res.push(_sample);
    //     }
    //   }
    // } catch (err) {
    //   console.log('error in fake data generation', err);
    // }
    return res;
};

const columns: TableHeaderDataProps[] = BP?.tableheaderData;

export const CounterfeitAlert = (props: Iprops) => {
    const { TABLE_ROWS_PER_PAGE } = defaultConfig;
    const theme = useContext(ThemeContext);
    const [data, setData] = React.useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [selectedBatch, setSelectedBatch] = useState<any>();
    const [selectedCity, setSelectedCity] = useState<any>();
    const [selectedRegion, setSelectedRegion] = useState<any>();
    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [dateLt, setDateLt] = useState<any>();
    const [dateGt, setDateGt] = useState<any>();
    const [products, setProducts] = useState<any>();
    const [batches, setBatches] = useState<any>();
    const [userActionKey, setUserActionKey] = React.useState('');
    const [navItemKey, setNavItemKey] = useState<string>();
    const [validateForm, setValidateForm] = useState<any>(null);
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<string>("10");
    const [filterChips, setFilterChips] = useState<any>();
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'xs',
        backdrop: false,
        show: false,
    });
    useEffect(() => {
        const app = EventBus.on(FORM_VALUES, value => {
            formContext = { ...value };
        });
        return function () {
            app.off();
        };
    }, []);
    const _cunterfeits = Array.isArray(props.counterfeitData) && props.counterfeitData.find(item => {
        if (item?.data) {
            return true
        }
    });
    const schema = _cunterfeits ? _cunterfeits?.data : BP?.enrolments;
    let pageCount = Math.ceil((_cunterfeits?.count) / (TABLE_ROWS_PER_PAGE));
    React.useEffect(() => {
        generateFakeData(schema, schema.length).then((res) => {
            res && Array.isArray(res) && setData([...res]);
        });
    }, [props.counterfeitData]);

    React.useEffect(() => {
        setProductsAndBatches()
    }, [props?.productsAndBatches]);

    const setProductsAndBatches = () => {
        let _products = Array.isArray(props?.productsAndBatches) && props?.productsAndBatches.map(item => {
            return ({
                key: item?.gtin,
                label: item?.name,
                value: item?.gtin
            });
        });
        setProducts(_products);
        let _filterBatches = Array.isArray(props?.productsAndBatches) && props?.productsAndBatches.filter(item => selectedProduct?.includes(item?.gtin)) || [];
        const _batcheIdentifiers = _filterBatches?.map(x => x.Batches?.map(y => y?.batchIdentifier)).flat();
        const _uniqBatchIdentifiers = _.uniq(_batcheIdentifiers);

        const _batches = _uniqBatchIdentifiers.map(item => {
            return ({
                key: item,
                label: item,
                value: item
            })
        });

        setBatches(_batches);

        let _filterChips = Array.isArray(BP?.filters) && BP?.filters.find(item => item?.key === 'displayed');
        let _filter = Array.isArray(_filterChips?.chips) && _filterChips?.chips.map(item => {
            if (item?.key === 'products') {
                return ({
                    ...item,
                    filters: _products,
                    defaultValues: selectedProduct
                })
            }
            if (item?.key === 'batches') {
                return ({
                    ...item,
                    filters: _batches
                })
            }
            else {
                return item
            }
        });

        let _chipsFiltered = [{
            ..._filterChips,
            chips: _filter
        }];
        setFilterChips(_chipsFiltered);
    }

    const onProductSelect = (value: any[]) => {
        setSelectedProduct(value);
        let formData = {
            gtin_in: value,
            batchNo_in: selectedBatch,
            city_contains_i: selectedCity,
            region_contains_i: selectedRegion,
            country_contains_i: selectedCountry,
            createdAt_lt: dateLt,
            createdAt_gte: dateGt
        }
        props?.counterfeitFilterApply && props?.counterfeitFilterApply(rowsPerPage, formData);
    }

    const onBatchSelect = (value: any[]) => {
        setSelectedBatch(value);
        let formData = {
            gtin_in: selectedProduct,
            batchNo_in: selectedBatch,
            city_contains_i: selectedCity,
            region_contains_i: selectedRegion,
            country_contains_i: selectedCountry,
            createdAt_lt: dateLt,
            createdAt_gte: dateGt
        }
        props?.counterfeitFilterApply && props?.counterfeitFilterApply(rowsPerPage, formData);
    }

    const onCitySelect = (value: string) => {
        setSelectedCity(value);
        let formData = {
            gtin_in: selectedProduct,
            batchNo_in: selectedBatch,
            city_contains_i: value,
            region_contains_i: selectedRegion,
            country_contains_i: selectedCountry,
            createdAt_lt: dateLt,
            createdAt_gte: dateGt
        }
        props?.counterfeitFilterApply && props?.counterfeitFilterApply(rowsPerPage, formData);
    }

    const onRegionSelect = (value: string) => {
        setSelectedRegion(value);
        let formData = {
            gtin_in: selectedProduct,
            batchNo_in: selectedBatch,
            city_contains_i: selectedCity,
            region_contains_i: value,
            country_contains_i: selectedCountry,
            createdAt_lt: dateLt,
            createdAt_gte: dateGt
        }
        props?.counterfeitFilterApply && props?.counterfeitFilterApply(rowsPerPage, formData);
    }

    const onCountrySelect = (value: string) => {
        setSelectedCountry(value);
        let formData = {
            gtin_in: selectedProduct,
            batchNo_in: selectedBatch,
            city_contains_i: selectedCity,
            region_contains_i: selectedRegion,
            country_contains_i: value,
            createdAt_lt: dateLt,
            createdAt_gte: dateGt
        }
        props?.counterfeitFilterApply && props?.counterfeitFilterApply(rowsPerPage, formData);
    }

    const onDateBeforeChange = (date: Date) => {
        setDateLt(date);
        let formData = {
            gtin_in: selectedProduct,
            batchNo_in: selectedBatch,
            city_contains_i: selectedCity,
            region_contains_i: selectedRegion,
            country_contains_i: selectedCountry,
            createdAt_lt: date,
            createdAt_gte: dateGt
        }
        props?.counterfeitFilterApply && props?.counterfeitFilterApply(rowsPerPage, formData);
    }

    const onDateAfterChange = (date: Date) => {
        setDateGt(date);
        let formData = {
            gtin_in: selectedProduct,
            batchNo_in: selectedBatch,
            city_contains_i: selectedCity,
            region_contains_i: selectedRegion,
            country_contains_i: selectedCountry,
            createdAt_lt: dateLt,
            createdAt_gte: date
        }
        props?.counterfeitFilterApply && props?.counterfeitFilterApply(rowsPerPage, formData);
    }

    const renderDrawerBody = () => {
        if (navItemKey === 'filter') {
            return (
                <>
                    {/* <FilterV
                    filterFields={filterChips}
                    onFilterValueChange={(values)=>onFilterChange(values)}
                    sectionStyle={{margin:10}}
                    chipContainerStyle={{margin:10}}
                    activeChipColor={"primary"}
                /> */}
                    <CheckPickerV
                        title={"Select Products"}
                        data={products}
                        value={selectedProduct}
                        containerStyle={{ margin: 5 }}
                        checkPickerStyle={{ width: '100%' }}
                        onChange={onProductSelect}
                    />
                    <CheckPickerV
                        title={"Select Batch"}
                        data={batches}
                        value={selectedBatch}
                        containerStyle={{ margin: 5 }}
                        checkPickerStyle={{ width: '100%' }}
                        onChange={onBatchSelect}
                    />
                    <SelectInputV
                        title={"Select City"}
                        data={props?.scanCities}
                        value={selectedCity}
                        containerStyle={{ margin: 5 }}
                        selectInputStyle={{ width: '100%' }}
                        onChange={onCitySelect}
                    />
                    <SelectInputV
                        title={"Select Region"}
                        data={props?.scanRegions}
                        value={selectedRegion}
                        containerStyle={{ margin: 5 }}
                        selectInputStyle={{ width: '100%' }}
                        onChange={onRegionSelect}
                    />
                    <SelectInputV
                        title={"Select Country"}
                        data={props?.scanCountries}
                        value={selectedCountry}
                        containerStyle={{ margin: 5 }}
                        selectInputStyle={{ width: '100%' }}
                        onChange={onCountrySelect}
                    />
                    <DatePickerV
                        value={dateLt}
                        onChange={(date) => onDateBeforeChange(date)}
                        title={"Date Before"}
                        containerStyle={{ margin: 5 }}
                        datePickerStyle={{ width: '100%' }}
                        oneTap={true}
                    />
                    <DatePickerV
                        value={dateGt}
                        onChange={(date) => onDateAfterChange(date)}
                        title={"Date After"}
                        containerStyle={{ margin: 5 }}
                        datePickerStyle={{ width: '100%' }}
                        oneTap={true}
                    />
                    {/* <FormV
                components={_filterFormFields}
                formContext={formContext}
                validateForm={validateForm}
                defaultValues={defaultValuesDS}
                // onValidateResult={handleValidateResult}
                /> */}
                    {/* <ButtonV
                buttonTitle={"ApplyFilter"}
                onPress={onFilterSubmit}
                color={"yellow"}
                /> */}
                </>
            )
        }
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const onFilterClick = () => {
        let _drawer = drawer;
        _drawer.show = true;
        _drawer.title = "Apply Filters";
        setNavItemKey('filter');
        setDrawer({ ..._drawer });
    };

    const handlePageChange = (event: any, value: number) => {
        setPage(value);
        props.counterfeitPageChange && props.counterfeitPageChange(value, rowsPerPage);
    }

    const handleRowsSelection = (value: string) => {
        setRowsPerPage(value);
        props?.counterfeitPageChange && props.counterfeitPageChange(page, value)
    }

    const bodyContent = () => {
        return (
            <>
                <FBGridV justify={"center"} align={'middle'}>
                    <FBItemV colspan={23} >
                        {/* {props?.counterfeitStats &&
                            <PanelV shaded style={{ backgroundColor: '#fff', marginBottom: 15, marginTop: 15 }}>
                                <DashboardCardsV items={props?.counterfeitStats}
                                />
                            </PanelV>
                        } */}
                    </FBItemV>
                    <FBItemV colspan={23}>
                        <View style={StyleSheet.flatten([styles.filterStyle])}>
                            <div onClick={onFilterClick}>
                                <IconButtonV
                                    icon={{
                                        name: 'filter'
                                    }}
                                />
                            </div>

                        </View>
                    </FBItemV>
                    <FBItemV colspan={23}>
                        <div style={StyleSheet.flatten(styles.paginationStyle)}>
                            <Pagination color="primary" shape={"rounded"} count={pageCount} page={page} onChange={handlePageChange} variant="outlined" shape="rounded" style={{ width: '50%' }} />
                            <SelectInputV
                                title={"Rows per Page:"}
                                searchable={false}
                                value={rowsPerPage}
                                data={BP?.pagination}
                                inline={true}
                                selectInputStyle={{ width: 80 }}
                                onSelect={handleRowsSelection}
                            />
                        </div>
                    </FBItemV>
                    <FBItemV colspan={23}>
                        {
                            props?.counterfeitLoading ? (
                                <Placeholder.Grid style={StyleSheet.flatten([styles.placeholderStyle])} rowHeight={30} rows={10} columns={8} active />
                            ) :
                                (
                                    <div style={StyleSheet.flatten([styles.tableStyle])}>
                                        <TableV
                                            onRowClick={(data) => { }}
                                            checkedAppearance={true}
                                            oddRowColor={theme?.palette.tableOddRowColor}
                                            evenRowColor={theme?.palette.tableEvenRowColor}
                                            table={{
                                                autoHeight: true,
                                                headerHeight: 40,
                                                rowHeight: 50,
                                                wordWrap: true,
                                                //cellBordered: true,
                                                // data: [...data],
                                                data: [...data]
                                            }}
                                            columns={columns}
                                        />
                                    </div>
                                )
                        }
                    </FBItemV>

                </FBGridV>
            </>
        );

    };
    const onNavItemClick = (eventKey: string) => {
        setUserActionKey(eventKey);
        if (eventKey === 'newUser') {
            let _drawer = { ...drawer };
            _drawer.title = "New User";
            _drawer.placement = BP?.drawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
    };
    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
};
