import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { ProvenanceMapModalSM } from './ProvenanceMapModal.sm';
import { ProvenanceMapModalLG } from './ProvenanceMapModal.lg';

export interface ProvenanceMapModalProps {
    mapData: object;
    handleBackPress?(): void;
}

export const ProvenanceMapModal = (props: ProvenanceMapModalProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <ProvenanceMapModalSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    if(bp === 'lg')
    return <ProvenanceMapModalLG {...props} />;
};
