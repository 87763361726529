import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import {ChipV} from 'react-chip-mui';
// import {AvatarV, AvatarSize} from 'react-native-avatar-vfw';
import {AvatarV} from 'react-avatar-mui';
import {Avatar, Chip} from '@material-ui/core';
import {useProductView} from '../provider';
import blueprint from '../config/default-blueprint.json';

const BP = blueprint.Chips;

interface ChipsProps {};

export const Chips = (props: ChipsProps) => {
  const {chipList} = useProductView();
    const styles = {
      divStyle: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: 5,
        marginBottom: 5
      },
      chipStyle: {
        backgroundColor: '#ffffff',
        padding: 15,
        margin: 10
      },
      containerStyle: {
        marginBottom: 20,
        marginLeft: 20
      }
    };

    const _getChips = () => {
        let renderedChips : any[]  = [];
      chipList && chipList?.chipItems &&
        chipList?.chipItems.forEach((item, index) => {
            renderedChips.push(
              <Chip
                label={item.title}
                style={{
                  backgroundColor: '#fff',
                  margin: 5,
                  padding: 5,
                  boxShadow:
                    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
                key={item.key}
                avatar={
                  <Avatar
                    src={item.imageUri}
                    alt={item.title}
                    variant={'circle'}
                    sizes={'medium'}
                    style={{padding: 2, backgroundColor: '#d3d3d3'}}
                  />
                }
                // chipStyle={StyleSheet.flatten([styles.chipStyle])}
              />,
            );
        });
        return (
          <View style={StyleSheet.flatten([styles.divStyle])}>
            <ScrollView horizontal>{renderedChips}</ScrollView>
          </View>
        );
    }

    return <View style={StyleSheet.flatten([styles.containerStyle])}>{_getChips()}</View>;
}