import React, { useEffect, useState } from 'react';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import Alert from 'rsuite/lib/Alert';
import { ResetPasswordPage } from './components';
import { useProvDashboard } from '../Dashboard/provider';

export const ResetPassword = () => {

    const history = useHistory();
    const { resetPassword, resetPasswordData, resetPasswordError, resetPasswordLoading } = useProvDashboard();

    let url_string: string = window.location.href;
    const url = new URL(url_string);
    const email = url.searchParams.get("email");
    const token = url.searchParams.get("token");

    React.useEffect(() => {
        if (
            resetPasswordError &&
            resetPasswordError.graphQLErrors &&
            Array.isArray(resetPasswordError.graphQLErrors) &&
            resetPasswordError.graphQLErrors[0] &&
            resetPasswordError.graphQLErrors[0].message
        ) {
            Alert.error(resetPasswordError.graphQLErrors[0].message)
        }
    }, [resetPasswordError]);

    if (resetPasswordLoading) {
        return (<LogoSpinnerV uri={'https://i.ibb.co/bWBCqc6/we-do-verify-icon.png'} />);
    }

    if (resetPasswordData) {
        Alert.success(resetPasswordData.changePassword)
        history.push('/');
    }

    const _handleREsetPassword = (password: string) => {
        resetPassword({
            variables: {
                email: email,
                password: password,
                passwordResetToken: token
            }
        });
    }

    const _renderBody = () => {
        if (resetPasswordLoading) {
            return <LogoSpinnerV uri={'https://i.ibb.co/bWBCqc6/we-do-verify-icon.png'} />
        }
        else {
            return (
                <>
                    <ResetPasswordPage
                        onResetPassword={_handleREsetPassword}
                    />
                </>
            )
        }
    }

    return (
        <>
            {_renderBody()}
        </>
    )
}

// const [email, setEmail] = useState<string>();
// const [token, setToken] = useState<string>();
// const { PasswordReset, resetPasswordData, resetPasswordLoading, resetPasswordError } = provResetPassword();

// useEffect(() => {
//     const url = window.location.href;
//     _getVerifyData(url);
// });

// useEffect(() => {
//     if (resetPasswordData) {
//         Alert.success("Reset Password Successful, please login from app", 10000);
//     }
//     if (resetPasswordError) {
//         Alert.error("Reset Password Failed please try Again", 5000);
//     }
// }, [resetPasswordData, resetPasswordError]);

// const onResetPassword = (password: string) => {
//     console.log('on reset password pressed', password);
//     PasswordReset({
//         variables: {
//             email: email,
//             password: password,
//             passwordResetToken: token
//         }
//     });
// }

// const _getVerifyData = (url: string) => {
//     // setUrl(url);
//     let params = url.split("/").slice(-2);
//     const queryString = window.location.search;
//     const urlParams = new URLSearchParams(queryString);
//     const email = urlParams.get('email');
//     const token = urlParams.get('token');
//     if (email && token) {
//         setEmail(email);
//         setToken(token);
//     }
//     console.log('params', token);
// }