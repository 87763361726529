import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { IProps } from './index';
import Panel from 'rsuite/lib/Panel';
import config from '../../config/default-blueprint.json';
import { AvatarV } from 'react-native-avatar-vfw';
import Avatar from 'rsuite/lib/Avatar';
import { NavV } from 'react-nav-rs';
import { Icon } from 'rsuite';
import moment from 'moment';
import { useProductView } from '../../provider';

const BP = config?.footer

export const FooterSM = (props: IProps) => {

    const { productDetails } = useProductView();
    const [data, setData] = React.useState<any>({
        telephone: "",
        website: "",
        socialLinks: []
    });


    const styles = {
        panalStyle: {
            backgroundColor: '#ececec'
        },
        copyrightpanalStyle: {
            backgroundColor: '#fcfcfc',
            margin: '0 auto',
            justifyContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
            textAlign: 'center'
        },
        titleStyle: {
            display: 'block',
            fontSize: 16,
            fontWeight: 700,
            textAlign: 'center',
            paddingTop: 20
        },
        contactItemStyle: {
            padding: 5
        },
        iconStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        }
    }

    let companyDetails = productDetails?.companyDetails && productDetails?.companyDetails;
    if (!!companyDetails && Array.isArray(companyDetails)) {
        let _data = data;
        _data.telephone = '';
        _data.website = '';
        _data.socialLinks = [];
        companyDetails.forEach((element, index) => {
            if (element?.compKey === 'telephone') {
                _data.telephone = element?.compValue;
            }
            if (element?.compKey === 'Website') {
                _data.website = element?.compValue;
            }
            if (element?.compKey === 'twitter') {
                _data.socialLinks.push({
                    key: 'twitter',
                    icon: "https://i.ibb.co/k693xvN/twitter.png",
                    link: element?.compValue
                });
            }
            if (element?.compKey === 'linkedIn') {
                _data.socialLinks.push({
                    key: 'linkedIn',
                    icon: "https://i.ibb.co/8jdMD1q/linkedIn.png",
                    link: element?.compValue
                });
            }
            if (element?.compKey === 'facebook') {
                _data.socialLinks.push({
                    key: 'facebook',
                    icon: "https://i.ibb.co/dQqVRvc/facebook.png",
                    link: element?.compValue
                });
            }
            if (element?.compKey === 'instagram') {
                _data.socialLinks.push({
                    key: 'instagram',
                    icon: "https://i.ibb.co/LzxGhRm/instagram.png",
                    link: element?.compValue
                });
            }
            if (element?.compKey === 'youtube') {
                _data.socialLinks.push({
                    key: 'youtube',
                    icon: "https://i.ibb.co/Yc8cnCx/youtube.png",
                    link: element?.compValue
                });
            }
        })
    }

    const _getcontactDetails = () => {
        return (
            <>
                {
                    Array.isArray(BP?.contactDetails?.details) && BP?.contactDetails?.details.map(item => {
                        if (item.key === 'phone' && !!data.telephone) {
                            return (
                                <View style={StyleSheet.flatten([styles.contactItemStyle])}>
                                    <Text style={{ textAlign: 'center', fontSize: 12, color: '#fff' }}><a href={`tel:${data.telephone}`}>{data.telephone}</a></Text>
                                </View>
                            )
                        }
                        if (item.key === 'email' && !!data?.website) {
                            return (
                                <View style={StyleSheet.flatten([styles.contactItemStyle])}>
                                    <Text style={{ textAlign: 'center', fontSize: 12, color: '#fff' }}><a href={data?.website} target="_blank">{data?.website}</a></Text>
                                </View>
                            )
                        }
                    })
                }
            </>
        )
    }

    const onFooterNavSelect = (eventKey: string) => {
        // console.log('eventkey', eventKey);
        props?.onFooterNavSelect && props?.onFooterNavSelect(eventKey);
    }

    const _getSocialLinks = () => {
        return (
            <View style={StyleSheet.flatten([styles.iconStyle])}>
                {
                    (!!data?.socialLinks) && Array.isArray(data?.socialLinks) ?
                        data?.socialLinks?.map((item, key) => {
                            return (
                                <a href={item.link} target="_blank" style={{ padding: 5 }}>
                                    <AvatarV source={{ uri: item.icon }} rounded />
                                </a>
                            )
                        }) : null
                }</View>
        )
    }


    return (
        <FBGridV justify="center">
            <FBItemV colspan={24}>
                <Panel style={StyleSheet.flatten([styles.panalStyle])}>
                    <Text style={StyleSheet.flatten([styles.titleStyle])}>
                        {BP?.contactDetails?.title}
                    </Text>
                    {_getcontactDetails()}
                    <Text style={StyleSheet.flatten([styles.titleStyle])}>
                        {BP?.socialLinks?.title}
                    </Text>
                    {
                        _getSocialLinks()
                    }
                </Panel>
                <Panel style={StyleSheet.flatten([styles.copyrightpanalStyle])}>
                    <div style={{ width: '100%', margin: '0 auto', display: 'block', justifyContent: 'center', alignSelf: 'center', alignItems: 'center' }}>
                        <Avatar
                            src={BP?.copyright?.logo}
                            circle
                        />
                    </div>
                    <NavV
                        items={BP?.copyright?.footerNav}
                        appearance={'subtle'}
                        onSelect={onFooterNavSelect}
                        style={{ width: '100%', display: 'block', margin: '0 auto' }}
                    />
                    <Text>
                        <Icon
                            icon={"copyright"}
                        /> {moment().year()}{" "}
                        {BP?.copyright?.copyRightText?.company} {"All rights reserved."}
                    </Text>
                    {BP?.copyright?.copyRightText?.poweredBy ? (
                        <Text>
                            {' powered by'}{' '}
                            <a
                                href={BP?.copyright?.copyRightText?.poweredByURL}
                                target="_blank"
                            >
                                {' '}
                                {BP?.copyright?.copyRightText?.poweredBy}
                            </a>
                        </Text>
                    ) : null}
                </Panel>
            </FBItemV>

        </FBGridV>
    )
}
