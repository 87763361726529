import React, { useContext } from 'react';
import {
    Image,
    View,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
} from 'react-native';

import { TooltipV } from 'react-tooltip-mui';
import { ImageV } from 'react-native-image-vfw';
import { TextV } from 'react-native-text-vfw';
import { IconV } from 'react-native-icon-vfw';
import { GoogleMapV } from '../../../../widgets/GoogleMapV';
import { useViewport } from 'react-viewport-provider';
import { ThemeContext } from '../../../../providers/theme';

interface ProvenanceMapModalProps {
    mapData: object;
};

export const ProvenanceMapModalLG = ({
    mapData
}: ProvenanceMapModalProps) => {
    const _markers = mapData.markers;
    const _defaultCenter = mapData.defaultCenter;
    const _defaultZoom = mapData.defaultZoom;
    const _data = mapData.data;
    const [size, setSize] = React.useState(
        Array(_markers?.length).fill(1),
    );
    const [zoomCenter, setZoomCenter] = React.useState({
        center: _defaultCenter,
        zoom: _defaultZoom,
    });
    const theme = useContext(ThemeContext);
    const { width, height } = useViewport();
    const styles = {
        contentContainer: {
            flexDirection: 'column',
            marginTop: 10,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            // width: 1 * width,
            height: height,
            backgroundColor: theme?.palette.ternary,
        },
        tooltipIcon: {
            backgroundColor: 'white',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            shadowOpacity: 0.75,
            shadowRadius: 10,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowOffset: { height: 0, width: 0 },
        },
        thumbnailStyle: {
            width: 0.5 * width,
            height: 0.3 * height,
            alignItems: 'center'
        },
        carouselStyle: {
            position: 'absolute',
            top: 0.7 * height
        }
    };
    const onChildClick = (key: number, childProps: any) => { };
    const onChildMouseEnter = (key: number, childProps: any) => {
        let _size = size;
        _size[key] = 1.8;
        setSize(_size);
    };
    const onChildMouseLeave = (key: number, childProps: any) => {
        let _size = size;
        _size[key] = 1;
        setSize(_size);
    };
    const onBoundsChange = (
        center: number[],
        zoom: number,
        bounds: number[],
        marginBounds: number[],
    ) => { };

    const renderItem = (tooltip, index) => {
        //Currently supports only react icons/images, not react-native icons/images
        return (
            <View
                style={StyleSheet.flatten([
                    styles.tooltipIcon,
                    {
                        width: size[tooltip?.id] * (tooltip?.image?.size + 8),
                        height: size[tooltip?.id] * (tooltip?.image?.size + 8),
                        borderRadius: tooltip?.image?.size
                            ? (size[tooltip?.id] * (tooltip?.image?.size + 8)) / 2
                            : 0,
                    },
                ])}
            >
                <Image
                    key={index}
                    source={{ uri: tooltip?.image?.uri }}
                    style={{
                        resizeMode: 'contain',
                        backgroundColor: 'transparent',
                        width: size[tooltip?.id] * tooltip?.image?.size,
                        height: size[tooltip?.id] * tooltip?.image?.size,
                        borderRadius: tooltip?.image?.size
                            ? (size[tooltip?.id] * tooltip?.image?.size) / 2
                            : 0,
                    }}
                />
            </View>
        );
    };

    const renderMarker = (marker: object | any, index: number) => {
        return (
            <TooltipV
                key={index}
                title={marker?.description}
                tooltipStyle={{
                    maxWidth: 200,
                    fontSize: 20,
                    backgroundColor: theme?.palette.primary,
                }}
                arrowStyle={{ color: theme?.palette.primary }}
                children={[marker]}
                renderItem={renderItem}
                placement={'top'}
                arrow
            />
        );
    };

    const onThumbnailPress = (data: any, index: number) => {
        setZoomCenter({
            center: {
                lat: data?.lat,
                lng: data?.lng,
            },
            zoom: 11,
        });
    };

    return (
        <View style={styles.contentContainer}>
            <GoogleMapV
                width={0.6 * width}
                height={0.9 * height}
                bootstrapURLKeys={{ key: mapData.googleMapAPIKey }}
                defaultCenter={mapData.defaultCenter}
                defaultZoom={mapData.defaultZoom}
                center={zoomCenter?.center}
                zoom={zoomCenter?.zoom}
                markers={_markers}
                renderMarker={renderMarker}
                onChildClick={onChildClick}
                onChildMouseEnter={onChildMouseEnter}
                onChildMouseLeave={onChildMouseLeave}
                onBoundsChange={onBoundsChange}
            />
            <View style={StyleSheet.flatten([styles.carouselStyle, { marginTop: -20 }])}>
                <ScrollView
                    horizontal
                    contentContainerStyle={styles.thumbnailStyle}
                >
                    {_data.map((data, index) => (
                        <TouchableOpacity
                            onPress={() => onThumbnailPress(data, index)}
                        >
                            <ImageV
                                source={data?.image?.uri}
                                imageStyle={{
                                    width: 0.1 * width,
                                    height: 0.2 * height,
                                    resizeMode: 'cover',
                                    borderRadius: 10,
                                    marginHorizontal: 10,
                                    shadowOpacity: 0.75,
                                    shadowRadius: 10,
                                    shadowColor: 'rgba(0,0,0,0.9)',
                                    shadowOffset: { height: 0, width: 0 },
                                }}
                            />
                            <TextV
                                type={'title1'}
                                style={{ fontSize: 10, marginLeft: 10 }}
                            >
                                {data?.title}
                            </TextV>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
            </View>
        </View>
    );
};
