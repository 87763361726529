
import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useState, useEffect } from 'react';
import { pretty } from 'react-native-helpers';
import { Dashboard } from './components';
import Alert from 'rsuite/lib/Alert';
import {useProvDashboard} from './provider';
import { useAuth } from '../../services/auth-service/auth.provider';
import config from '../../config/default.config.json';

// const console: LoggerService = new LoggerService(
//   new ConsoleLogger('Dashboard:container'),
// );
let REVIEW_PER_PAGE = 12;

export const DashboardView = () => {
  const {TABLE_ROWS_PER_PAGE} = config;
  const [updateBatchSuccess, setUpdateBatchSuccess] = useState<boolean>(false);
  const [activeProduct, setActiveProduct] = useState<string>();
  const [userProfile, setUSerProfile] = useState<any>(null);
  const [transactionCriteria, setTransactionCriteria] = useState<object>({});
  const [enrolmentCriteria, setEnrolmentCriteria] = useState<object>({});
  const [counterfeitCriteria, setCounterfeitCriteria] = useState<object>({});
  const [feedbackCriteria, setFeedbackCriteria] = useState<object>({});
  const {orgProfData, 
    transactions, 
    enrolments,
    feedbackStats,
    getFeedbackStats,
    products,
    batches,
    feedbacks,
    getProducts,
    getBatches,
    updateBatch,
    updateBatchData,
    updateBatchError,
    updateBatchLoading,
    getCounterfeits,
    counterfeitLoading,
    counterfeitError,
    counterFiets,
    getCounterfeitStats,
    counterfeitStats,
    getTransactions,
    transactionsLoading,
    transactionsError,    
    getTransactionStats,          
    transactionStats,
    transStatsLoading,
    transStatsError,
    getFeedback,
    feedbackLoading,
    getProductAndBatches,
    productAndBatchesLoading,
    productsAndBatches,
    productAndBatchesError,
    getCityCountryRegion,
    scanCities,
    scanRegions,
    scanCountries,
    getQRCode,
    getQRData,
    getQRError,
    getQRLoading
  } = useProvDashboard();

  const { getUserProfile } = useAuth();

  useEffect(()=>{
    getUserProfile().then(userProfile => {
      setUSerProfile(userProfile)
    });
  },[]);

  useEffect(() => {
    getProductAndBatches({
      variables:{}
    });
    getCityCountryRegion({
      variables:{}
    });
  }, [])

  useEffect(()=>{
    if(updateBatchData){
      Alert.success('Batch updated successfully', 2000);
      setUpdateBatchSuccess(true);
      getBatches({
        variables:{
          gtin:activeProduct
        }
      });
    }
    if(updateBatchError){
      Alert.error('Batch updated failed, please try again', 3000);
    }
  }, [updateBatchData, updateBatchError]);

  const onSideNavItemSelect = (eventKey:string) => {
    if(eventKey === 'products'){
      getProducts({
        variables:{}
      });
    }
    if(eventKey === 'counterfeit'){
      getCounterfeits({
        variables:{
          criteria:{
            isCounterfiet: true
          },
          skip: 0
        }
      });
      // getCounterfeitStats({
      //   variables: { isCounterfiet: true}
      // });
    }
    
    if(eventKey === 'transactions'){
      getTransactions({
        variables:{
          sortOrder:'desc',
          criteria: {},
          limit:TABLE_ROWS_PER_PAGE
        }
      });
      // getTransactionStats({
      //   variables:{}
      // });
    }
    if(eventKey === 'feedback'){
      getFeedbackStats({
        variables:{}
      });
      getFeedback({
        variables: {
          sortOrder:'desc',
          criteria: {},
          limit: REVIEW_PER_PAGE,
        }
      });
    }
    if(eventKey === 'enrolment'){
      getFeedback({
        variables: {
          sortOrder:'desc',
          criteria: {},
          limit: TABLE_ROWS_PER_PAGE,
        }
      });
      // getEnrolmentStats({
      //   variables:{}
      // });
    }
  }

  const onEnrolmentFilterApply = (rowsPerPage: string, formData:any) => {
    setEnrolmentCriteria(formData);
    getFeedback({
      variables: {
        sortOrder:'desc',
        criteria: formData,
        limit: parseInt(rowsPerPage),
      }
    });
  }

  const counterfeitFilterApply = (rowsPerPage: string, formData: any) => {
    setCounterfeitCriteria(formData);
    getCounterfeits({
      variables: {
        criteria: {
          isCounterfiet: true,
          ...formData
        },
        limit: parseInt(rowsPerPage),
      }
    });
  }

  const onFeedbackFilterApply = (rowsPerPage: string, formData: any) => {
    setFeedbackCriteria(formData);
    getFeedback({
      variables: {
        sortOrder: 'desc',
        criteria: formData,
        limit: parseInt(rowsPerPage),
      }
    });
  }

  const onProductSelect = (eventKey:string) => {
    setActiveProduct(eventKey);
    // getQRCode({
    //   variables:{
    //     appMode: "web",
    //     gtin:eventKey, 
    //     generatePdf:true
    //   }
    // });
    getBatches({
      variables:{
        gtin:eventKey
      }
    });
  }

  const onBatchSelect = (eventKey: string, batchName: string) => {
    getQRCode({
      variables: {
        appMode: "web",
        gtin: activeProduct,
        generatePdf: true,
        batch: batchName
      }
    });
  }

  const onUpdateBatch=(key:string, formData:any)=>{
    updateBatch({
      variables:{
        where:{
          _id:key,
        },
        data:{
          ...formData
        }
      }      
    });
  }

  const feedbackPageChange = (pageNo:number, rowsPerPage:string) => {
    getFeedback({
      variables:{
        sortOrder:'desc',
        criteria: feedbackCriteria,
        limit:parseInt(rowsPerPage),
        skip:(pageNo -1) * parseInt(rowsPerPage)
      }
    });
  }
  const transactionsPageChange =(pageNo:number, rowsPerPage: string) =>{
    getTransactions({
      variables:{
        sortOrder:'desc',
        criteria: transactionCriteria,
        limit:parseInt(rowsPerPage),
        skip: (pageNo -1) * parseInt(rowsPerPage)
      }
    });
  }

  const onFilterApply = (rowsPerPage: string, formData?:any) =>{
    setTransactionCriteria(formData);
    getTransactions({
      variables:{
        sortOrder:'desc',
        criteria:formData,
        limit: parseInt(rowsPerPage), 
      }
    });
  }

  const enrolmentsPageChange = (pageNo: number, rowsPerPage:string) => {
    getFeedback({
      variables: {
        sortOrder:'desc',
        criteria: enrolmentCriteria,
        limit: parseInt(rowsPerPage),
        skip: (pageNo -1) * parseInt(rowsPerPage)
      }
    });
  }

  const counterfeitPageChange = (pageNo: number) => {
    getCounterfeits({
      variables:{
        criteria:{
          isCounterfiet: true,
          ...counterfeitCriteria
        },
        limit: TABLE_ROWS_PER_PAGE,
        skip: (pageNo -1) * TABLE_ROWS_PER_PAGE
      }
    });
  }

  const onRatingFilterApply = (key:any) => {
    if(key === "all") {
      getFeedback({
        variables: {
          sortOrder:'desc',
          criteria: {
          },
          limit: TABLE_ROWS_PER_PAGE,
        }
      });
    }
    else {
      getFeedback({
        variables: {
          sortOrder:'desc',
          criteria: {
            rating: parseInt(key)
          },
          limit: TABLE_ROWS_PER_PAGE,
        }
      });
    }
  }
  
  const _renderBody = () => {
    return (
      <>
        <Dashboard
          userProfile={userProfile}
          sidebarNavItemSelect={onSideNavItemSelect}
          onProductSelect={onProductSelect}
          onBatchSelect={onBatchSelect}
          orgProfileData={orgProfData}
          transactions={transactions}
          transactionsPageChange={transactionsPageChange}
          transactionStats={transactionStats}
          tranactionsLoading={transactionsLoading}
          enrolments={enrolments}
          enrolmentsPageChange={enrolmentsPageChange}
          products={products}
          batches={batches}
          feedbacks={feedbacks}
          onUpdateBatch={onUpdateBatch}
          updateBatchSuccess={updateBatchSuccess}
          activeGtin={activeProduct}
          counterfeitLoading={counterfeitLoading}
          counterfeitData={counterFiets}
          counterfeitPageChange={counterfeitPageChange}
          counterfeitStats={counterfeitStats}
          feedbackPageChange={feedbackPageChange}
          feedbackLoading={feedbackLoading}
          onEnrolmentFilterApply={onEnrolmentFilterApply}
          productsAndBatches={productsAndBatches}
          onFilterApply={onFilterApply}
          getQRData={getQRData?.encodeBarcode?.pdfBase64}
          onRatingFilter={onRatingFilterApply}
          scanCities={scanCities}
          scanRegions={scanRegions}
          scanCountries={scanCountries}
          counterfeitFilterApply={counterfeitFilterApply}
          feedbackStats={feedbackStats}
          onFeedbackFilterApply={onFeedbackFilterApply}
        />
      </>
    );
  };

  return <>{_renderBody()}</>;
};
