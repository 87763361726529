import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {DashboardLG} from './Dashboard.lg';
import {DashboardMD} from './Dashboard.md';
import {DashboardSM} from './Dashboard.sm';

export interface IProps {
  userProfile?:any;
  opeProfileData?:any;
  orgProfileData?:any;
  sidebarNavItemSelect?(eventKey?:string): void;
  categoryClick?(eventKey?: string): void;
  inviteIssuerFormData?(formContext?:any): void;
  onInviteIssuer?(formData?:any):void;
  onInviteVerifier?(formData?:any): void;
  onInviteHolders?(formData?:any): void;
  onTemplateSelect?(eventKey?:any): void;
  onIssueCredential?(issueData?:any): any;
  inviteUserLoading?:boolean;
  inviteUserStaus?:boolean;
  credentialCategoryLoading?:boolean;
  credentialCategries?:any;
  templateData?: any[];
  issueCredentialSuccess?:any[];
  issueCredLoading?:boolean;
  findTemplates?:boolean;
  sideNavItems?:any[];
  transactions?:any[];
  tranactionsLoading?:boolean;
  enrolments?:any[];
  enrolmentStats?:any[];
  transactionStats?:any[];
  products?:any[];
  batches?:any[];
  feedbacks?:any[];
  onProductSelect?(eventKey?:string):void;
  onBatchSelect?(eventKey?: any, title?: string): void;
  onUpdateBatch?(key?:string, formData?:any):void;
  updateBatchSuccess?:boolean;
  activeGtin?:string;
  counterfeitLoading?: boolean;
  counterfeitData?:any[];
  counterfeitStats?:any[];
  feedbackPageChange?(pageNo?: number, rowsPerPage?:string): void;
  feedbackLoading?:boolean;
  feedbackStats?: any[];
  transactionsPageChange?(pageNo?:number, rowsPerPage?:string):void;
  enrolmentsPageChange?(pageNo?:number, rowsPerPage?:string):void;
  counterfeitPageChange?(pageNo?:number):void;
  productsAndBatches?:any[];
  onFilterApply?(rowsPerPage: string, formData?: any): void;
  onEnrolmentFilterApply?(rowsPerPage: string, formData?: any): void;
  counterfeitFilterApply?(rowsPerPage: string, formData?: any): void;
  onFeedbackFilterApply?(rowsPerPage: string, formData?: any): void;
  onRatingFilter?(key?:any):void;
  getQRData?:string;
  scanCities?:any[];
  scanRegions?:any[];
  scanCountries?:any[];
}

export const Dashboard = (props: IProps) => {
  const {bp} = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <DashboardSM {...props}/>;
  }

  // if (bp === 'md') {
  //   return <DashboardMD />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <DashboardLG  {...props}/>;
};
