import React from 'react';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import Alert from 'rsuite/lib/Alert';
import { ForgotPasswordPage } from './components';
import { useProvDashboard } from '../Dashboard/provider';

export const ForgotPassword = () => {
    const history = useHistory();
    const { forgotPassword, forgotPasswordData, forgotPasswordError, forgotPasswordLoading } = useProvDashboard();

    const _handleForgotPassword = (email: string) => {
        console.log(`email`, email);
        forgotPassword({
            variables: {
                email: email
            }
        });
    }

    React.useEffect(() => {
        if (
            forgotPasswordError &&
            forgotPasswordError.graphQLErrors &&
            Array.isArray(forgotPasswordError.graphQLErrors) &&
            forgotPasswordError.graphQLErrors[0] &&
            forgotPasswordError.graphQLErrors[0].message
        ) {
            console.log(`forgotPasswordDataError`, forgotPasswordError)
            Alert.error(forgotPasswordError.graphQLErrors[0].message)
        }
    }, [forgotPasswordError]);

    if (forgotPasswordLoading) {
        return (<LogoSpinnerV uri={'https://i.ibb.co/bWBCqc6/we-do-verify-icon.png'} />);
    }

    if (forgotPasswordData) {
        console.log(`forgotPasswordData`, forgotPasswordData)
        Alert.success(forgotPasswordData.resetPassword)
        history.push('/login');
    }

    if (forgotPasswordError) {
        console.log(`forgotPasswordError`, forgotPasswordError)
    }

    const _renderBody = () => {
        return (
            <>
                <ForgotPasswordPage onForgotPassword={_handleForgotPassword} />
            </>
        )
    }

    return (
        <>
            {_renderBody()}
        </>
    )
}