import React, { useContext, useLayoutEffect, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../providers/theme';
import { useViewport } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { Icon, Whisper, Tooltip, Divider, Avatar, Button } from 'rsuite';
import { ButtonV, IconButtonV } from 'react-button-rs';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { SelectInputV } from 'react-select-input-rs';
import Loader from 'rsuite/lib/Loader';
import { FormV, FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import Pagination from '@material-ui/lab/Pagination';
import Placeholder from 'rsuite/lib/Placeholder';
import { CheckPickerV } from 'react-checkpicker-rs';
import { DatePickerV } from 'react-datepicker-rs';
import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { DrawerV } from 'react-drawer-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { StatisticsCard } from './statistics';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { DashboardCardsV } from 'react-dashboard-cards-web';
import { PanelV } from 'react-panel-rs';
import { EventBus } from 'event-bus-vfs';
import moment from 'moment';
import defaultConfig from '../../../../../../config/default.config.json';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.enrolment;
let formContext = {};

type Iprops = {
    enrolmentLoading?: boolean;
    enrolments?: any[];
    enrolmentStats?: any[];
    enrolmentsPageChange?(pageNo?: number, rowsPerPage?: string): void;
    productsAndBatches?: { gtin: string, name?: string, Batches: { batchIdentifier?: string }[] }[];
    onEnrolmentFilterApply?(rowsPerPage: string, formData?: any): void;
    scanCities?: any[];
    scanCountries?: any[];
    scanRegions?: any[];
};

const styles = {
    leftcontainerStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    rightContainerStyle: {
        float: 'right',
        display: 'flex',
    },
    tableStyle: {
        margin: '0 auto',
    },
    activeStyle: {
        backgroundColor: 'green',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    InactiveStyle: {
        backgroundColor: 'orange',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    revokedStyle: {
        backgroundColor: 'red',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    paginationStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'cnter',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#fff',
        borderTop: '1px solid #ccc',
        width: '100%'
    },
    placeholderStyle: {
        margin: '0 auto',
        backgroundColor: '#fff',
        padding: 10
    },
    statusStyle: { alignItems: 'center', display: 'flex', flexDirection: 'row' },
    filterStyle: { justifyContent: 'flex-end', display: 'block', float: 'right', margin: 8 },
    resetFilterStyle: {
        justifyContent: 'flex-end',
        paddingTop: 20
    }
};
const generateFakeData = async (schema, rows = 1) => {
    let res = [];
    try {
        if (schema) {
            for (let i = 0; i < rows; ++i) {
                let _sample = { ...schema[i] };
                _sample.birthDate = _sample.birthDate ? moment(_sample?.birthDate).format('DD-MM-YYYY') : '-';
                _sample.telephone = (!!_sample?.telephone) ? _sample?.telephone : '-';
                _sample.email = (!!_sample?.email) ? _sample?.email : '-';
                _sample.anniversaryDate = _sample.anniversaryDate ? moment(_sample?.anniversaryDate).format('DD-MM-YYYY') : '-';
                _sample.createdAt = _sample.createdAt ? moment(_sample?.createdAt).format('DD-MM-YYYY HH:mm:ss') : '-';
                _sample.city = (!!_sample.city) ? _sample.city : '-';
                _sample.region = (!!_sample.region) ? _sample.region : '-';
                _sample.country = (!!_sample.country) ? _sample.country : '-';
                res.push(_sample);
            }
        }
    } catch (err) { }
    // try {
    //   if (schema) {
    //     for (let i = 0; i < rows; ++i) {
    //       const sample = await jsf.resolve(BP?.users);
    //       let _sample = {...sample.user};
    //       _sample.avatar = `https://ui-avatars.com/api/?name=${_sample.name}`;
    //       res.push(_sample);
    //     }
    //   }
    // } catch (err) {
    //   console.log('error in fake data generation', err);
    // }
    return res;
};

const columns: TableHeaderDataProps[] = BP?.tableheaderData;

export const Enrolment = (props: Iprops) => {
    const { TABLE_ROWS_PER_PAGE } = defaultConfig;
    const theme = useContext(ThemeContext);
    const [data, setData] = React.useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<string>("10");
    const [userActionKey, setUserActionKey] = React.useState('');
    const [navItemKey, setNavItemKey] = useState<string>();
    const [validateForm, setValidateForm] = useState<any>(null);
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [selectedBatch, setSelectedBatch] = useState<any>();
    const [selectedCity, setSelectedCity] = useState<any>();
    const [selectedRegion, setSelectedRegion] = useState<any>();
    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [dateLt, setDateLt] = useState<any>();
    const [dateGt, setDateGt] = useState<any>();
    const [products, setProducts] = useState<any>();
    const [batches, setBatches] = useState<any>();
    const [filterType, setFilterType] = React.useState<any>({
        selectedProduct: [],
        selectedBatch: [],
        selectedCity: "",
        selectedRegion: "",
        selectedCountry: "",
        dateLt: "",
        dateGt: ""

    });
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'xs',
        backdrop: false,
        show: false,
    });

    useEffect(() => {
        const app = EventBus.on(FORM_VALUES, value => {
            formContext = { ...value };
        });
        return function () {
            app.off();
        };
    }, []);

    React.useEffect(() => {
        setProductsAndBatches()
    }, [props?.productsAndBatches]);

    const setProductsAndBatches = () => {

        let _products = Array.isArray(props?.productsAndBatches) && props?.productsAndBatches.map(item => {
            return ({
                key: item?.gtin,
                label: item?.name,
                value: item?.gtin
            });
        });
        setProducts(_products);
        let _filterBatches = Array.isArray(props?.productsAndBatches) && props?.productsAndBatches.filter(item => filterType?.selectedProduct?.includes(item?.gtin)) || [];

        const _batcheIdentifiers = _filterBatches?.map(x => x.Batches?.map(y => y?.batchIdentifier)).flat();
        const _uniqBatchIdentifiers = _.uniq(_batcheIdentifiers);


        const _batches = _uniqBatchIdentifiers.map(item => {
            return ({
                key: item,
                label: item,
                value: item
            })
        });

        setBatches(_batches);
    }

    const _enrolments = Array.isArray(props.enrolments) && props.enrolments.find(item => {
        if (item?.data) {
            return true
        }
    });
    let pageCount = Math.ceil((_enrolments?.count) / parseInt(rowsPerPage));

    const schema = _enrolments?.data ? _enrolments?.data : BP?.enrolments;

    React.useEffect(() => {
        generateFakeData(schema, schema.length).then((res) => {
            res && Array.isArray(res) && setData([...res]);
        });
    }, [props.enrolments]);

    const handlePageChange = (event: any, value: number) => {
        setPage(value);
        props.enrolmentsPageChange && props.enrolmentsPageChange(value, rowsPerPage);
    }

    const handleRowsSelection = (value: string) => {

        setRowsPerPage(value);
        props?.enrolmentsPageChange && props.enrolmentsPageChange(page, value)
    }

    const onProductSelect = (value: any[]) => {
        let _filters = filterType;
        _filters.selectedProduct = value;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: value,
            batchNo_in: filterType?.selectedBatch,
            city_contains_i: filterType?.selectedCity,
            region_contains_i: filterType?.selectedRegion,
            country_contains_i: filterType?.selectedCountry,
            createdAt_lt: filterType?.dateLt,
            createdAt_gte: filterType?.dateGt
        }
        props?.onEnrolmentFilterApply && props?.onEnrolmentFilterApply(rowsPerPage, formData);
    }

    const onBatchSelect = (value: any[]) => {
        let _filters = filterType;
        _filters.selectedBatch = value;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: filterType?.selectedProduct,
            batchNo_in: value,
            city_contains_i: filterType?.selectedCity,
            region_contains_i: filterType?.selectedRegion,
            country_contains_i: filterType?.selectedCountry,
            createdAt_lt: filterType?.dateLt,
            createdAt_gte: filterType?.dateGt
        }
        props?.onEnrolmentFilterApply && props?.onEnrolmentFilterApply(rowsPerPage, formData);
    }

    const onCitySelect = (value: string) => {
        let _filters = filterType;
        _filters.selectedCity = value;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: filterType?.selectedProduct,
            batchNo_in: filterType?.selectedBatch,
            city_contains_i: value,
            region_contains_i: filterType?.selectedRegion,
            country_contains_i: filterType?.selectedCountry,
            createdAt_lt: filterType?.dateLt,
            createdAt_gte: filterType?.dateGt
        }
        props?.onEnrolmentFilterApply && props?.onEnrolmentFilterApply(rowsPerPage, formData);
    }

    const onRegionSelect = (value: string) => {
        let _filters = filterType;
        _filters.selectedRegion = value;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: filterType?.selectedProduct,
            batchNo_in: filterType?.selectedBatch,
            city_contains_i: filterType?.electedCity,
            region_contains_i: value,
            country_contains_i: filterType?.selectedCountry,
            createdAt_lt: filterType?.dateLt,
            createdAt_gte: filterType?.dateGt
        }
        props?.onEnrolmentFilterApply && props?.onEnrolmentFilterApply(rowsPerPage, formData);
    }

    const onCountrySelect = (value: string) => {
        let _filters = filterType;
        _filters.selectedCountry = value;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: filterType?.selectedProduct,
            batchNo_in: filterType?.selectedBatch,
            city_contains_i: filterType?.selectedCity,
            region_contains_i: filterType?.selectedRegion,
            country_contains_i: value,
            createdAt_lt: filterType?.dateLt,
            createdAt_gte: filterType?.dateGt
        }
        props?.onEnrolmentFilterApply && props?.onEnrolmentFilterApply(rowsPerPage, formData);
    }

    const onDateBeforeChange = (date: Date) => {
        let _filters = filterType;
        _filters.dateLt = date;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: filterType?.selectedProduct,
            batchNo_in: filterType?.selectedBatch,
            city_contains_i: filterType?.selectedCity,
            region_contains_i: filterType?.selectedRegion,
            country_contains_i: filterType?.selectedCountry,
            createdAt_lt: date,
            createdAt_gte: filterType?.dateGt
        }
        props?.onEnrolmentFilterApply && props?.onEnrolmentFilterApply(rowsPerPage, formData);
    }

    const onDateAfterChange = (date: Date) => {
        let _filters = filterType;
        _filters.dateGt = date;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: filterType?.selectedProduct,
            batchNo_in: filterType?.selectedBatch,
            city_contains_i: filterType?.selectedCity,
            region_contains_i: filterType?.selectedRegion,
            country_contains_i: filterType?.selectedCountry,
            createdAt_lt: filterType?.dateLt,
            createdAt_gte: date
        }
        props?.onEnrolmentFilterApply && props?.onEnrolmentFilterApply(rowsPerPage, formData);
    }

    const onResetFilter = () => {
        props?.onEnrolmentFilterApply && props?.onEnrolmentFilterApply(rowsPerPage, {});
        setFilterType({
            selectedProduct: [],
            selectedBatch: [],
            selectedCity: "",
            selectedRegion: "",
            selectedCountry: "",
            dateLt: "",
            dateGt: ""
        })
    }

    const renderDrawerBody = () => {
        if (navItemKey === 'filter') {
            return (
                <>
                    <CheckPickerV
                        title={"Select Products"}
                        data={products}
                        value={filterType?.selectedProduct}
                        containerStyle={{ margin: 5 }}
                        checkPickerStyle={{ width: '100%' }}
                        onChange={onProductSelect}
                    />
                    <CheckPickerV
                        title={"Select Batch"}
                        data={batches}
                        value={filterType?.selectedBatch}
                        containerStyle={{ margin: 5 }}
                        checkPickerStyle={{ width: '100%' }}
                        onChange={onBatchSelect}
                    />
                    <SelectInputV
                        title={"Select City"}
                        data={props?.scanCities}
                        value={filterType?.selectedCity}
                        containerStyle={{ margin: 5 }}
                        selectInputStyle={{ width: '100%' }}
                        onChange={onCitySelect}
                    />
                    <SelectInputV
                        title={"Select Region"}
                        data={props?.scanRegions}
                        value={filterType?.selectedRegion}
                        containerStyle={{ margin: 5 }}
                        selectInputStyle={{ width: '100%' }}
                        onChange={onRegionSelect}
                    />
                    <SelectInputV
                        title={"Select Country"}
                        data={props?.scanCountries}
                        value={filterType?.selectedCountry}
                        containerStyle={{ margin: 5 }}
                        selectInputStyle={{ width: '100%' }}
                        onChange={onCountrySelect}
                    />
                    <DatePickerV
                        value={filterType?.dateLt}
                        onChange={(date) => onDateBeforeChange(date)}
                        title={"Date Before"}
                        containerStyle={{ margin: 5 }}
                        datePickerStyle={{ width: '100%' }}
                        oneTap={true}
                    />
                    <DatePickerV
                        value={filterType?.dateGt}
                        onChange={(date) => onDateAfterChange(date)}
                        title={"Date After"}
                        containerStyle={{ margin: 5 }}
                        datePickerStyle={{ width: '100%' }}
                        oneTap={true}
                    />
                    <View style={StyleSheet.flatten([styles.resetFilterStyle])}>
                        <ButtonV
                            buttonTitle={'Reset'}
                            buttonStyle={{ width: '100%', }}
                            onPress={onResetFilter}
                        />
                    </View>
                </>
            )
        }
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const onFilterClick = () => {
        let _drawer = drawer;
        _drawer.show = true;
        _drawer.title = "Apply Filters";
        setNavItemKey('filter');
        setDrawer({ ..._drawer });
    };

    const bodyContent = () => {
        return (
            <>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {/* <PanelV >
                            <DashboardCardsV items={props?.enrolmentStats}
                                cardContainerStyle={{ justifyContent: 'space-around', marginLeft:20, marginRight:20 }}
                                cardStyle={{ justifyContent: 'space-around'}}
                                
                            />
                        </PanelV> */}
                </View>
                <FBGridV justify={"center"} align={'middle'}>
                    <FBItemV colspan={23}>
                        <View style={StyleSheet.flatten([styles.filterStyle])}>
                            <div onClick={onFilterClick}>
                                <IconButtonV
                                    icon={{
                                        name: 'filter'
                                    }}
                                />
                            </div>

                        </View>
                    </FBItemV>
                    <FBItemV colspan={23}>
                        <div style={StyleSheet.flatten(styles.paginationStyle)}>
                            <Pagination color="primary" shape={"rounded"} count={pageCount} page={page} onChange={handlePageChange} variant="outlined" shape="rounded" style={{ width: '50%' }} />
                            <SelectInputV
                                title={"Rows per Page:"}
                                searchable={false}
                                value={rowsPerPage}
                                data={BP?.pagination}
                                inline={true}
                                selectInputStyle={{ width: 80 }}
                                onSelect={handleRowsSelection}
                            />
                        </div>
                    </FBItemV>
                    <FBItemV colspan={23}>
                        {
                            props?.enrolmentLoading ? (
                                <Placeholder.Grid style={StyleSheet.flatten([styles.placeholderStyle])} rowHeight={30} rows={10} columns={8} active />
                            ) :
                                (
                                    <div style={StyleSheet.flatten([styles.tableStyle])}>
                                        <TableV
                                            onRowClick={(data) => { }}
                                            checkedAppearance={true}
                                            oddRowColor={theme?.palette.tableOddRowColor}
                                            evenRowColor={theme?.palette.tableEvenRowColor}
                                            table={{
                                                autoHeight: true,
                                                headerHeight: 40,
                                                rowHeight: 50,
                                                wordWrap: true,
                                                //cellBordered: true,
                                                // data: [...data],
                                                data: [...data],
                                                // rowClassName:"custom-row"
                                            }}
                                            columns={columns}
                                        />
                                    </div>
                                )
                        }
                    </FBItemV>

                </FBGridV>
            </>
        );

    };
    const onNavItemClick = (eventKey: string) => {
        setUserActionKey(eventKey);
        if (eventKey === 'newUser') {
            let _drawer = { ...drawer };
            _drawer.title = "New User";
            _drawer.placement = BP?.drawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
    };
    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
};
