import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useViewport } from 'react-viewport-provider';
import { ButtonV, ButtonType } from 'react-native-button-vfw';
import { ThemeContext } from '../../../providers/theme';
import config from '../../../config/default.config.json';

interface PromoProps {
    onPress?(): void;
    promoButtonText: string;
};

export const PromoButton = (props: PromoProps) => {
    const theme = React.useContext(ThemeContext);
    const { bp } = useViewport();
    const styles = StyleSheet.create({
        buttonContainer: {
            justifyContent: (bp !== 'xs' && bp !== 'sm') ? 'flex-start' : 'center',
            alignItems: (bp !== 'xs' && bp !== 'sm') ? 'flex-start' : 'center',
            alignSelf: (bp !== 'xs' && bp !== 'sm') ? 'flex-start' : 'center',
        },
        buttonStyle: {
            borderRadius: 10,
            width: 180,
            marginBottom: 20
        },
    });

    return (
        <ButtonV
            type={ButtonType.Solid}
            title={props.promoButtonText}
            onPress={props.onPress}
            buttonStyle={StyleSheet.flatten([
                styles.buttonStyle,
                { backgroundColor: theme?.palette?.secondary },
            ])}
            containerStyle={StyleSheet.flatten([styles.buttonContainer])}
        />
    );
}