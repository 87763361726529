import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {ProductPageSM} from './ProductPage.sm';
import { ProductPageMD } from './ProductPage.md';
import {ProductPageLG} from './ProductPage.lg';

export interface ProductPageProps {
  onFabClick?(): void;
  headerTitle?: string;
  headerTitleStyle?: object;
  onInfoClick?(): void;
  handleLGMarkerClick?(item: any): void;
  onRating?(value: number): void;
  FeedbackClick?(): void;
  PromoEnrollmentClick?(): void;
  carouselImages?: [];
  handleLGHeaderInfoClick?(): void;
  footerContents?: any;
  googleMapAPIKey?:string;
}

export const ProductPage = (props: ProductPageProps) => {
  const {bp} = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <ProductPageSM {...props} />;
  }

  if (bp === 'md') {
    return <ProductPageMD {...props} />;
  }

  if(bp === 'lg')
  return <ProductPageLG {...props} />;
};
