import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { CarouselV } from 'react-carousel-rs';
import { ThemeContext } from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';

const BP = blueprint.Carousel;

interface CarouselProps {
  images?: any[];
};

export const CarouselLG = (props: CarouselProps) => {
  const theme = useContext(ThemeContext);
  const renderItems = (item: any, index: number) => {
    return (
      // <div key={index} style={{borderRadius: 20}}>
      //   <ImageV
      //     source={item?.image}
      //     imageStyle={{
      //       width: 500,
      //       height: 400,
      //       resizeMode: 'cover',
      //       //  borderRadius: 20,
      //     }}
      //   />
      // </div>

      <div key={index} style={{ backgroundColor: 'tomato' }}>
        <img
          src={item?.image.uri}
          style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20, height: 350, objectFit: 'cover' }}
        />
      </div>
    );
  };

  function ValidURL(str: any) {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      // alert('Please enter valid URL.');
      return false;
    } else {
      return true;
    }
  }

  const _getImages = () => {
    const images = props.images;
    if (images && images[0]?.uri && images[0]?.uri !== null) {
      let convertImages = [];
      images && images.forEach((element) => {
        if (element.uri !== null && ValidURL(element.uri)) {
          convertImages.push(
            {
              image: element.uri
            }
          );
        }
      })
      return convertImages;
    } else {
      let Images = [];
      images && images.forEach((element) => {
        if (element.image !== null && ValidURL(element.image)) {
          Images.push(
            {
              image: element.image
            }
          );
        }
      })
      return Images;
    }
  }

  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
      <CarouselV
        images={_getImages()}
        imageStyle={{
          height: '400px',
          backgroundColor: theme?.palette?.ternary,
          objectFit: 'contain'
        }}
        carouselStyle={{
          backgroundColor: theme?.palette?.ternary,
        }}
        placement={'bottom'}
        shape={'bar'}
        autoplay
        autoplayInterval={2000}
      />
    </View>
  );
}