import React from 'react';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { View, StyleSheet, Text } from 'react-native';
import Icon from 'rsuite/lib/Icon';
import Avatar from 'rsuite/lib/Avatar';
import config from './config/default-blueprint.json';

const BP = config?.pageNotFound;

export const PageNotFoundSM = () => {
    const styles = {
        bodyStyle: {
            height: '98vh',
            width: '100%',
            backgroundImage: 'linear-gradient(#2a4564, #141e30)',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center'
        },
        spinnerStyle: {
            color: '#fff',
            position: 'absolute',
            top: '46%',
            left: '45%'
        },
        imgStyle: {
            position: 'absolute',
            top: '38%',
            left: '25%'
        },
        imgStyle: {
            margin: '0 auto',
            top: '36%',
            left: '40%'
        },
        titleStyle: {
            textAlign: 'center',
            fontSize: 24,
            fontWeight: 700,
            color: '#fff',
        },
        desciptionStyle: {
            width: '90%',
            alignItems: 'center',
            alignSelf: 'center',
            display: 'block',
            textAlign: 'center',
            lineHeight: 26,
            fontSize: 14,
            color: '#fff',
            padding: 10
        },
        poweredByStyle: {
            display:'flex',
            flexDirection: 'row',
            alignItems:'center',
            justifyContent:'center'
        }
    }
    return (
        <FBGridV justify={'center'}>
            <FBItemV colspan={16} style={StyleSheet.flatten([styles.bodyStyle])}>
                <View style={{ margin: '5%', padding: 10, border: '1px solid #fff', borderRadius: 25 }}>
                    <img src={BP?.logo} width={120} height={'auto'} style={styles.imgStyle} />
                    <Text style={styles.titleStyle}>
                        {BP?.description1}
                    </Text>
                    <Text style={styles.desciptionStyle}>
                        {BP?.description2}
                    </Text>
                    <Text style={styles.desciptionStyle}>
                        {BP?.description3} <br /><a href={BP?.link}>{BP?.linkText}</a><br /> to know more
                </Text>
                {
                 BP?.poweredBy ? 
                <View style={StyleSheet.flatten([styles.poweredByStyle])}>
                <Avatar
                    src={BP?.poweredByLogo}                  
                    circle
                    size={'xs'}
                />
                <Text style={{color:'#fff'}}>{" powered by"} <a href={BP?.poweredByLink} target="_blank"> {BP?.poweredBy}</a></Text>  
                </View>: null
                }
                </View>
            </FBItemV>
        </FBGridV >
    )
}

