import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useEffect, useState } from 'react';
import { pretty } from 'react-native-helpers';
import { useMutation } from '@apollo/react-hooks';
import { LoginPage } from './components';
import Alert from 'rsuite/lib/Alert';
import { LOGIN_USER } from './mutation';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import config from '../../config/default.config.json';
import auth from '../../services/authService';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { useProvLogin } from './provider';

import { useAuth } from '../../services/auth-service/auth.provider';

const console: LoggerService = new LoggerService(
  new ConsoleLogger('Login:container'),
);

export const Login = () => {
  const { loginUserData, loginUserError, loginUserLoading, LoginUser } = useProvLogin();
  // const [LoginUser, { data, loading, error, called }] = useMutation(LOGIN_USER);
  const { signIn, getAuthToken } = useAuth();
  const [remember, setRememberMe] = useState<boolean>(false);
  const [isPreRendering, setIsPreRendering] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (
      loginUserError &&
      loginUserError.graphQLErrors &&
      Array.isArray(loginUserError.graphQLErrors) &&
      loginUserError.graphQLErrors[0] &&
      loginUserError.graphQLErrors[0].message
    ) {
      // Alert.warning(`${loginUserError.graphQLErrors[0].message.message}`, 10000);
      history.push('/dashboard');
    }
  }, [loginUserError]);

  useEffect(() => {
    getAuthToken().then((token: any) => {
      if (token) {
        history.push('/dashboard');
      }
      setIsPreRendering(false);
    })
  }, [])

  if (loginUserLoading || isPreRendering) {
    return <LogoSpinnerV uri={'https://i.ibb.co/bWBCqc6/we-do-verify-icon.png'} />;
  }
  // useEffect(() => {
  //   if (data) {
  //     console.info(`on login : ${pretty(data)}`);
  //     Alert.success('Successfully Logged In', 2000);
  //     if (data.signIn.accessToken) {
  //       auth.signIn(data.signIn.accessToken);
  //       auth.setActor(data.signIn.features[0]);
  //       if (data.signIn.userStatus === 'REGISTERED') {
  //         history.push('./register', {nextStep: 1});
  //       } else if (data.signIn.userStatus === 'ORG_CREATED') {
  //         history.push('/dashboard');
  //       }
  //     }
  //   }
  // }, [data]);

  if (loginUserData) {
    Alert.success('Successfully Logged In', 2000);
    if (loginUserData.signIn.accessToken) {
      signIn({
        newToken: loginUserData.signIn?.accessToken,
        rememberMe: remember,
        actorName: loginUserData.signIn?.features[0],
        userProfile: {
          fullName: loginUserData.signIn?.fullName,
          telephone: loginUserData.signIn?.telephone,
          did: loginUserData.signIn?.did,
          email: loginUserData.signIn?.email,
          logo: loginUserData.signIn?.logo
        },
      });
      history.push('/dashboard');
      window.onpopstate = function (event) {
        history.go(1);
      };
      // if (data.signIn.userStatus === 'REGISTERED') {
      //   history.push('./register', { nextStep: 1 });
      // } else if (data.signIn.userStatus === 'ORG_CREATED') {
      //   history.push('/dashboard');
      // }
    }
  }

  const onLoginClick = (
    email: string,
    password: string,
    rememberMe: boolean,
  ) => {
    console.debug(
      `email: ${email}, password: ${password}, rememberMe: ${rememberMe}`,
    );
    if (!!rememberMe) {
      setRememberMe(true)
    } else if (remember === true) {
      setRememberMe(false)
    }
    if (email && password) {
      LoginUser({
        variables: {
          credential: {
            email: email,
            password: password,
          },
        },
      });
    }
  };

  const _handleForgotPasswordPressed = () => {
    history.push('/forgotpassword')
  }

  const _renderBody = () => {
    return <LoginPage onLogin={onLoginClick} forgotPasswordPressed={_handleForgotPasswordPressed} />;
  };
  return <> {_renderBody()}</>;
};
