import gql from 'graphql-tag';

export const GET_PRODUCTS = gql`
query getProducts{
    getSubscribedProducts{
        id
        ProductList{
          prodList{
            gtin
            id
            title
            subTitle
            description
            price
            image{
              uri
            }
          }
        }
    }
}
`;

export const GET_BATCHES = gql`
    query getBatches($gtin:String!){
        getBatchesWeb(
            gtin:$gtin
        ){
            id
            batches{
              key
              title  
              subtitle1
              tooltip
              subtile2
              icon{
                imageURI
                imgWidth
                imgHeight
              }
              tooltip
              details{
                key
                value
                label
              }
        }
    }
    }
`;

export const GET_COUNTERFEITS = gql`
query getCounterfeits($criteria:ScanLogQueryCriteriaInput, $skip:Int){
  getScanLogs(criteria:$criteria skip:$skip){
    data{
      productName
      ip
      city
      latitude
      scanType
      scannedHash
      serialNo
      longitude
      country
      region
      appMode
      who
    }
    count
  }
}
`;

export const GET_TRANSACTIONS = gql`
query getTransactions($criteria:ScanLogQueryCriteriaInput, $skip:Int, $limit:Int, $sortOrder:SortOrder){
  getScanLogs(criteria:$criteria skip:$skip limit:$limit sortOrder: $sortOrder){
    data{
      productName
      ip
      region
      city
      country
      batchNo
      scanType
      scannedHash
      serialNo
      longitude
      appMode
      createdAt
    }
    count
    scanCount
    tapCount
  }
}
`;

export const GET_TRANS_STATS = gql `
query getTransactionStats($isCounterfiet: Boolean){
  getScanLogStats(isCounterfiet: $isCounterfiet){
   id
    statistics{
      label
      count
      titleStyle{
        color
        fontSize
      }
      textStyle{
        color
        fontSize
      }
      icon{
        name
        size
        reverse
        style{
          color
          fontSize
        }
      }
      color2
      color1
    }
  }
}
`;

export const GET_COUNTERFEITS_STATS = gql`
query getCounterfeitStats($isCounterfiet: Boolean){
  getScanLogStats(isCounterfiet: $isCounterfiet){
   id
    statistics{
      label
      count
      titleStyle{
        color
        fontSize
      }
      textStyle{
        color
        fontSize
      }
      icon{
        name
        size
        reverse
        style{
          color
          fontSize
        }
      }
      color2
      color1
    }
  }
}
`;

export const GET_FEEDBACK_STATS = gql `
query getFeedbackStats{
  getFeedbackStats{
    key
    title
    rating
    count
    backgroundColor
  }
}
`;

export const GET_FEEDBACKS = gql`
  query getFeedback($criteria: FeedbackQueryCriteriaInput, $limit:Int, $skip:Int, $sort:FeedbackSortBy, $sortOrder:SortOrder){
    getFeedbacks(criteria:$criteria,limit:$limit, sort:$sort, skip:$skip, sortOrder:$sortOrder){
      skip
    sortOrder
    limit
    count
      data{
        _id
        email
        createdAt
        birthDate
        rating
        gtin
        comments
        name
        productName
        anniversaryDate
        telephone
        batchNo        
        region
        city
        country
      }
    }
  }
`;

export const GET_PRODUCT_AND_BATCHES = gql`
  query getProductAndBatches{
    getMyProductsAndBatches{
      _id
      gtin
      name
      Batches {
        _id
        batchIdentifier
      }
    }
  }
`;

export const GET_CITY_COUNTRY_REGION = gql`
  query getCityCountryRegion{
    getScanLogsRegionsList{
      city
      region
      country
    }
  }
`

// export const GET_ENROLMENTS = gql`

// `;

// export const GET_TRANSACTION_STATS = gql`

// `;

// export const GET_ENROLMENT_STATS = gql`

// `;
