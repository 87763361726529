import gql from 'graphql-tag';

export const UPDATE_FEEDBACK =  gql`
mutation($rating: Int!, $comments: String!, $email: String, $name: String, $contactNo: String){
    createFeedbackWeb(rating: $rating, comments: $comments, email: $email, name: $name, contactNo: $contactNo)
}
`;

export const CREATE_CONTEST = gql`
mutation(
    $name: String!
    $email: String!
    $birthDate: String!
    $telephone: String
    $anniversaryDate: String
    $barcodeHash: String
    $comments: String
    $rating: Int!
) {
    createContestEntryWeb(
        name: $name
        email: $email
        birthDate: $birthDate
        telephone: $telephone
        anniversaryDate: $anniversaryDate
        barcodeHash: $barcodeHash
        comments: $comments
        rating: $rating
    )
}
`;