import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import {
    FlatList,
    ScrollView,
    StyleSheet,
    TextStyle,
    TouchableOpacity,
    ViewStyle,
} from 'react-native';
import { useHistory } from 'react-router-dom';
import { titleCase } from 'title-case';
import { ListItemV } from 'react-native-list-item-vfw';
import { TextV } from 'react-native-text-vfw';
import { IconV } from 'react-native-icon-vfw';
import { AvatarV } from 'react-native-avatar-vfw';
import { useProductView } from '../../provider';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.ProductDetails;

interface ProductDetailsV {
}

export const ProductDetailsLG = ({
}: ProductDetailsV) => {
    const { productDetails } = useProductView();
    const data = productDetails;
    const history = useHistory();

    const _renderProductDetails = ({ item, index }: { item: any; index: any }) => {
        return (
            <ListItemV
                key={index}
                //@ts-ignore
                title={
                    item && typeof item.prodKey === 'string' && titleCase(item.prodKey)
                }
                //@ts-ignore
                titleStyle={StyleSheet.flatten([
                    styles.prodKeyStyle,
                    data.prodKeyColor && { color: data.prodKeyColor },
                ])}
                //@ts-ignore
                rightTitle={
                    item &&
                    typeof item.prodValue === 'string' &&
                    titleCase(item.prodValue)
                }
                rightContentContainerStyle={{ alignItems: 'flex-start' }}
                //@ts-ignore
                rightTitleStyle={StyleSheet.flatten([
                    styles.prodValueStyle,
                    data.prodValueColor ? { color: data.prodValueColor } : { color: '#acacac' },
                ])}
                leftElement={_renderLeftProdIcon(
                    item && item.icon,
                    item && item.prodKey,
                )}
            />
        );
    };

    const _renderLeftProdIcon = (icon: any, title: string) => {
        if (icon || title) {
            return (
                <AvatarV
                    source={icon}
                    size={15}
                    //@ts-ignore
                    title={title && typeof title === 'string' && title[0]}
                />
            );
        }
    };

    const _renderCompanyDetails = ({ item, index }: { item: any; index: any }) => {
        return (
            <TouchableOpacity onPress={() => _onDetailPress(item)}>
                <ListItemV
                    key={index}
                    //@ts-ignore
                    title={
                        item && typeof item.compKey === 'string' && titleCase(item.compKey)
                    }
                    //@ts-ignore
                    titleStyle={StyleSheet.flatten([
                        styles.prodKeyStyle,
                        data.prodKeyColor && { color: data.prodKeyColor },
                    ])}
                    //@ts-ignore
                    rightElement={
                        <TextV
                            type={'caption'}
                            children={
                                item &&
                                typeof item.compValue === 'string' &&
                                titleCase(item.compValue)
                            }
                            ellipsizeMode={'tail'}
                            numberOfLines={1}
                            style={StyleSheet.flatten([
                                styles.compValueStyle,
                                data.prodValueColor
                                    ? {
                                        color:
                                            ValidURL(item.compValue) === true
                                                ? '#05a9ef'
                                                : data.prodValueColor,
                                    }
                                    : {
                                        color:
                                            ValidURL(item.compValue) === true
                                                ? '#05a9ef'
                                                : '#acacac',
                                    },
                            ])}
                        />
                    }
                    leftElement={_renderLeftCompIcon(
                        item && item.icon,
                        item && item.compKey,
                    )}
                // rightTitle={
                //   item &&
                //   typeof item.compValue === 'string' &&
                //   titleCase(item.compValue)
                // }
                // rightTitleStyle={StyleSheet.flatten([
                //   styles.prodValueStyle,
                //   prodValueStyle,
                //   {color: prodValueColor},
                // ])}
                />
            </TouchableOpacity>
        );
    };

    const _renderLeftCompIcon = (icon: any, title: string) => {
        if (icon || title) {
            return (
                <AvatarV
                    source={icon}
                    size={15}
                    //@ts-ignore
                    title={title && typeof title === 'string' && title[0]}
                />
            );
        }
    };

    function ValidURL(str: any) {
        var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(str)) {
            // alert('Please enter valid URL.');
            return false;
        } else {
            return true;
        }
    }

    const _onDetailPress = async (item: any) => {
        if (ValidURL(item.compValue) === true) {
            // await WebBrowser.openBrowserAsync(
            //   item && typeof item.compValue === 'string' && item.compValue,
            // );
            window.open(item.compValue);
        }
    };

    const _renderProductName = () => {
        if (data.productName && typeof data.productName === 'string') {
            return (
                <TextV
                    type={'title3'}
                    children={titleCase(data.productName)}
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={StyleSheet.flatten([
                        styles.productNameStyle,
                        data.productNameStyle,
                        data.productNameColor && {
                            color: data.productNameColor,
                        },
                    ])}
                />
            );
        }
    };

    const _renderProductInfo = () => {
        if (data.productInfo && typeof data.productInfo === 'string') {
            return (
                <TextV
                    type={'headline'}
                    children={data.productInfo}
                    ellipsizeMode={'tail'}
                    numberOfLines={3}
                    style={StyleSheet.flatten([
                        styles.productInfoStyle,
                        data.productInfoStyle,
                        data.productInfoColor && {
                            color: data.productInfoColor,
                        },
                    ])}
                />
            );
        }
    };

    const _renderOrganizationLogo = () => {
        if (data.organizationLogo) {
            return (
                <AvatarV
                    source={data.organizationLogo}
                    size={100}
                    overlayContainerStyle={StyleSheet.flatten([
                        styles.organizationLogoOverlay,
                        data.organizationLogoOverlay && data.organizationLogoOverlay,
                    ])}
                    containerStyle={StyleSheet.flatten([
                        styles.organizationLogoContainer,
                        data.organizationLogoContainer && data.organizationLogoContainer,
                    ])}
                />
            );
        }
    };

    return (
        <ScrollView
            contentContainerStyle={StyleSheet.flatten([
                styles.container,
            ])}
            showsVerticalScrollIndicator={false}
        >
            {_renderOrganizationLogo()}

            {_renderProductName()}

            {_renderProductInfo()}


            {data.productDetails && Array.isArray(data.productDetails) && (
                <>
                    <TextV
                        type={'headline'}
                        children={
                            typeof data.productDetailTitle === 'string' &&
                            titleCase(data.productDetailTitle)
                        }
                        ellipsizeMode={'tail'}
                        numberOfLines={3}
                        style={StyleSheet.flatten([
                            styles.productDetailTitleStyle,
                            data.productDetailTitleColor && {
                                color: data.productDetailTitleColor,
                            },
                            data.productDetailTitleBgColor ? {
                                backgroundColor: data.productDetailTitleBgColor,
                            } : { backgroundColor: '#d3d3d3' },
                        ])}
                    />
                    <FlatList
                        data={data.productDetails}
                        //@ts-ignore
                        listKey={(item: any, index: any) => {
                            return `${index}-${new Date()}`;
                        }}
                        renderItem={_renderProductDetails}
                        showsVerticalScrollIndicator={false}
                    />
                </>
            )}
            {data.companyDetails && Array.isArray(data.companyDetails) && (
                <>
                    <TextV
                        type={'headline'}
                        children={
                            typeof data.companyDetailTitle === 'string' &&
                            titleCase(data.companyDetailTitle)
                        }
                        ellipsizeMode={'tail'}
                        numberOfLines={3}
                        style={StyleSheet.flatten([
                            styles.productDetailTitleStyle,
                            data.productDetailTitleColor && {
                                color: data.productDetailTitleColor,
                            },
                            data.productDetailTitleBgColor ? {
                                backgroundColor: data.productDetailTitleBgColor,
                            } : { backgroundColor: '#d3d3d3' },
                        ])}
                    />
                    <FlatList
                        data={data.companyDetails}
                        //@ts-ignore
                        listKey={(item: any, index: any) => {
                            return `${index}-${new Date()}`;
                        }}
                        renderItem={_renderCompanyDetails}
                        showsVerticalScrollIndicator={false}
                    />
                </>
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        paddingBottom: 20,
        margin: 5
    },
    productNameStyle: {
        fontSize: 20,
        letterSpacing: 1,
        textAlign: 'left',
        paddingVertical: 5,
    },
    productInfoStyle: {
        textAlign: 'left',
        fontSize: 13,
        paddingVertical: 10,
    },
    productDetailTitleStyle: {
        textAlign: 'left',
        fontSize: 14,
        fontWeight: '600',
        paddingLeft: 3,
    },
    prodKeyStyle: {
        fontSize: 13,
        left: -5,
    },
    prodValueStyle: {
        fontSize: 13,
    },
    compValueStyle: {
        width: '33%',
        fontSize: 13,
        textAlign: 'left',
        alignItems: 'flex-start'
    },
    organizationLogoOverlay: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    organizationLogoContainer: {
        alignSelf: 'center',
        margin: 10,
    },
});
