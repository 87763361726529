import React, { useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { ViewportProvider, useViewport } from 'react-viewport-provider';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { IconV } from 'react-native-icon-vfw';
import {useHistory} from 'react-router-dom';
import {EnterContest} from './components';
import {Alert} from 'rsuite';
import {useProductView} from '../ProductView/provider';
import {useAuth} from '../../services/auth-service/auth.provider';
import config from '../../config/default.config.json';
import logospinner from '../../../assets/we-do-verify-icon.png';

const FeedbackInternal = () => {
    const history = useHistory();
    // const {getHash} = useAuth();
    const { prodContestData, prodContestError, prodContestLoading, createContestEntryWeb} = useProductView();

    const _handleSubmit = async (formData: any) => {
        // let hash = await getHash();
            if (formData) {
                createContestEntryWeb({
                    variables: {
                        name: formData.name,
                        email: formData.email,
                        birthDate: formData.birthDate,
                        telephone: formData.telephone,
                        anniversaryDate: formData.anniversaryDate,
                        rating: formData.rating,
                        comments: formData.comments,
                        barcodeHash: config.hash
                    }
                })
            }        
    }
    // useEffect(() => {
    //     if()
    // }, []);

    if (prodContestError){
        console.error('error', prodContestError)
        Alert.error(prodContestError.message);
    }

    if (prodContestLoading) {
        return <LogoSpinnerV 
            uri={'https://i.ibb.co/bWBCqc6/we-do-verify-icon.png'}
        />
    }

    if (prodContestData){
        Alert.success('Thank you for participating!');
        window.history.go(-1);
    }

    const handleBackPress = () => {
        window.history.go(-1);
        return false;
    }

    const _handlePrivacyClick = () => {
        window.open('/privacy', '_blank');
    }

    const _handleTermsClick = () => {
        window.open('/termsandcondition', '_blank');
    }

    return (
        <>
            <TouchableOpacity hitSlop={{ top: 10, bottom: 10, left: 10 }}>
                <IconV
                    name={'closecircle'}
                    type={'antdesign'}
                    onPress={handleBackPress}
                    size={26}
                    color={'#000'}
                    containerStyle={{
                        alignSelf: 'flex-end',
                        marginTop: 20,
                        marginRight: 20,
                        marginBottom: 10,
                    }}
                />
            </TouchableOpacity>
        <View style={{alignItems: 'center'}}>
            <EnterContest 
                onContestSubmit={_handleSubmit}
                privacyClick={_handlePrivacyClick}
                termsClick={_handleTermsClick}
            />
        </View>
        </>
    );
}

export const FeedBackScreen = () => {
    return (
        <ViewportProvider>
            <FeedbackInternal />
        </ViewportProvider>
    );
}