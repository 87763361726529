import React, {useContext, useState,useEffect } from 'react';
import {View, StyleSheet, Text} from 'react-native';
import { ThemeContext } from '../../../../../../providers/theme';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { BodyTemplateV } from 'react-body-template-1';
import config from './config/default-blueprint.json';
import { EventBus } from 'event-bus-vfs';
import { DashboardCardsV } from 'react-dashboard-cards-web';
import { Panel, Rate } from 'rsuite';
import Pagination from '@material-ui/lab/Pagination';
import { ButtonV, IconButtonV } from 'react-button-rs';
import { PanelV } from 'react-panel-rs';
import { AvatarV, AvatarSize } from 'react-native-avatar-vfw';
import {FormV, FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import { DrawerV } from 'react-drawer-rs';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { FeedbackCardV } from 'react-feedback-card';
import {SelectInputV} from 'react-select-input-rs';
import { CheckPickerV } from 'react-checkpicker-rs';
import Modal from 'rsuite/lib/Modal';
import {IProps} from './index';

const BP = config?.feedBack;
let formContext = {};
const REVIEWS_PER_PAGE = 12;
export const FeedbackLG = (props:IProps) => {
    const theme = useContext(ThemeContext);
    const [page, setPage] = useState<number>(1);
    const [navItemKey, setNavItemKey] = useState<string>();
    const [validateForm, setValidateForm] = useState<any>(null);
    const [show, setShow] = useState<boolean>(false);
    const [modalData, setModalData] = useState<object>();
    const [products, setProducts] = useState<any>();
    const [batches, setBatches] = useState<any>();
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [selectedBatch, setSelectedBatch] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = useState<string>("12");
    const [filterType, setFilterType] = React.useState<any>({
        selectedProduct: [],
        selectedBatch: [],
    });
    const [drawer, setDrawer] = useState<any>({
        placement: 'right',
        title: '',
        size: 'xs',
        backdrop: false,
        show: false,
    });
    const onModalClose = () => {
        if (show) {
            setShow(false);
        }
    }
    useEffect(() => {
        const app = EventBus.on(FORM_VALUES, value => {
            formContext = { ...value };
        });
        return function () {
            app.off();
        };
    }, []);

    React.useEffect(() => {
        setProductsAndBatches()
    }, [props?.productsAndBatches]);

    const setProductsAndBatches = () => {
        let _products = Array.isArray(props?.productsAndBatches) && props?.productsAndBatches.map(item => {
            return ({
                key: item?.gtin,
                label: item?.name,
                value: item?.gtin
            });
        });
        setProducts(_products);
        let _filterBatches = Array.isArray(props?.productsAndBatches) && props?.productsAndBatches.filter(item => filterType?.selectedProduct?.includes(item?.gtin)) || [];
        const _batcheIdentifiers = _filterBatches?.map(x => x.Batches?.map(y => y?.batchIdentifier)).flat();
        const _uniqBatchIdentifiers = _.uniq(_batcheIdentifiers);

        const _batches = _uniqBatchIdentifiers.map(item => {
            return ({
                key: item,
                label: item,
                value: item
            })
        });

        setBatches(_batches);
    }

    const styles = {
        nameStyle:{
            fontSize:18,
            fontWeight:500,
            display:'block',
            textAlign:'center'
        },
        panelStyle:{
            backgroundColor:'#fff',
            minHeight:200,
            padding:5,
            margin:'10px 0px',
            alignItems:'center',
            cursor:'pointer'
        },
        testiStyle:{
            display:'block',
            fontSize:14,
            fontWeight:400,
            textAlign:'center',
        },
        testiModalStyle: {
            display: 'block',
            fontSize: 14,
            fontWeight: 400,
            textAlign: 'center',
            borderStyle: 'dotted',
            lineHeight: 30,
            border: 1,
            width: '80%',
            padding: 10,
            borderRadius: 10,
            marginTop: 10
        },
        avatarStyle:{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight:200,
        },
        paginationStyle: {
            display:'flex',
            flexDirection:'row',
            alignSelf: 'cnter',
            justifyContent:'space-between',
            padding: 10,
            backgroundColor: '#fff',
            borderTop: '1px solid #ccc',
            width: '100%'
        },
        modalStyle: {
            width: '70%',
            alignSelf: 'center',
            alignItems: 'center',
            margin: '4% auto 0 auto'
        },
        filterStyle: { justifyContent: 'flex-end', display: 'block', float: 'right', margin: 8 },
        resetFilterStyle: {
            justifyContent: 'flex-end',
            paddingTop: 20
        }
    }

    let _reviews = Array.isArray(props?.feedbacks) && props?.feedbacks.find(item=>{
        if(item?.data){
            return true
        }
    });

    let pageCount = Math.ceil((_reviews?.count) / parseInt(rowsPerPage));

    const onNavItemSelect = (eventKey: string) => {
        if (eventKey === 'filter') {
            let _drawer = drawer;
            _drawer.show = true;
            _drawer.title = "Apply Filters";
            setNavItemKey(eventKey);
            setDrawer({ ..._drawer });
        }
    }

    const oncardClick=(item: any)=>{
        if(!show){
            setShow(true);
            setModalData(item);
        }
    }

    const _getModalBody = () => {
        return (
            <View style={StyleSheet.flatten([styles.avatarStyle])}>
                <AvatarV 
                    source={{uri: modalData?.name?  `https://ui-avatars.com/api/?name=${modalData?.name}` : `https://ui-avatars.com/api/?name=${modalData?.email}`}}
                    rounded
                    size={AvatarSize?.Large}
                   />
                   <Text style={StyleSheet.flatten([styles.nameStyle])}>{modalData?.name ?? modalData?.email } </Text>
                    <Rate defaultValue={modalData?.rating} readOnly />
                    <Text style={StyleSheet.flatten([styles.testiModalStyle])}>{modalData?.comments}</Text>
            </View>
        )
    }

    const _getFeedbacks =() => {
        
        let _feedbacks:any = [];
        
        Array.isArray(_reviews?.data) && _reviews.data.forEach(item=>{
            _feedbacks.push(
                <FBItemV colspan={5}>
                <Panel shaded style={StyleSheet.flatten([styles.panelStyle])} onClick={()=>oncardClick(item)}>
                    <View style={StyleSheet.flatten([styles.avatarStyle])}>
                    <Text style={StyleSheet.flatten([styles.nameStyle])}>{item?.name ?? item?.email } </Text>
                    <Rate defaultValue={item?.rating} readOnly />
                    <Text style={StyleSheet.flatten([styles.testiStyle])}>{item?.comments?.substr(0, 100) + "..."}</Text>
                   </View> 
                </Panel>
            </FBItemV>
            )
        })
        return _feedbacks;
    }

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const onProductSelect = (value: any[]) => {
        let _filters = filterType;
        _filters.selectedProduct = value;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: value,
            batchNo_in: filterType?.selectedBatch
        }
        props?.onFeedbackFilterApply && props?.onFeedbackFilterApply(rowsPerPage, formData);
    }

    const onBatchSelect = (value: any[]) => {
        let _filters = filterType;
        _filters.selectedBatch = value;
        setFilterType({ ..._filters })
        let formData = {
            gtin_in: filterType?.selectedProduct,
            batchNo_in: value
        }
        props?.onFeedbackFilterApply && props?.onFeedbackFilterApply(rowsPerPage, formData);
    }

    const onResetFilter = () => {
        props?.onFeedbackFilterApply && props?.onFeedbackFilterApply(rowsPerPage, {});
        setFilterType({
            selectedProduct: [],
            selectedBatch: []
        })
    }

    const renderDrawerBody = () => {
        if(navItemKey === 'filter'){
            return(
                <>
                    <CheckPickerV
                        title={"Select Products"}
                        data={products}
                        value={filterType?.selectedProduct}
                        containerStyle={{ margin: 5 }}
                        checkPickerStyle={{ width: '100%' }}
                        onChange={onProductSelect}
                    />
                    <CheckPickerV
                        title={"Select Batch"}
                        data={batches}
                        value={filterType?.selectedBatch}
                        containerStyle={{ margin: 5 }}
                        checkPickerStyle={{ width: '100%' }}
                        onChange={onBatchSelect}
                    />
                    <View style={StyleSheet.flatten([styles.resetFilterStyle])}>
                        <ButtonV
                            buttonTitle={'Reset'}
                            buttonStyle={{ width: '100%', }}
                            onPress={onResetFilter}
                        />
                    </View>
                </>
            )
        }
    }

    const onStatsCardClick =(item:any)=>{
        props?.onRatingFilter && props?.onRatingFilter(item?.key)
    }
    const handlePageChange = (event: any, value: number) => {
        setPage(value);
        props.feedbackPageChange && props.feedbackPageChange(value, rowsPerPage);
    }
    const handleRowsSelection = (value:string) => {
        setRowsPerPage(value);
        props?.feedbackPageChange && props.feedbackPageChange(page, value)
    }

    const onFilterClick = () => {
        let _drawer = drawer;
        _drawer.show = true;
        _drawer.title = "Apply Filters";
        setNavItemKey('filter');
        setDrawer({ ..._drawer });
    };

    const _getData =() => {
        return(
            <FBGridV justify="space-around">
                <FBItemV colspan={23}>
                    {props.feedbackStats &&
                        <FeedbackCardV
                            cardsData={props.feedbackStats}
                            onCardClick={onStatsCardClick}
                            titleTextStyle={{ fontSize: 20 }}
                        />
                    }
                </FBItemV>
                 <FBItemV colspan={24}>
                 <div style={StyleSheet.flatten(styles.paginationStyle)}>
                        <Pagination color="primary" shape={"rounded"} count={pageCount} page={page} onChange={handlePageChange} variant="outlined" shape="rounded" style={{ width: '50%' }} />
                        <SelectInputV
                            title={"Rows per Page:"}
                            searchable={false}
                            value={rowsPerPage}
                            data={BP?.pagination}
                            inline={true}
                            selectInputStyle={{width:80}}
                            onSelect={handleRowsSelection}
                        />
                    </div>
                        </FBItemV>
                <FBItemV colspan={23}>
                    <View style={StyleSheet.flatten([styles.filterStyle])}>
                        <div onClick={onFilterClick}>
                            <IconButtonV
                                icon={{
                                    name: 'filter'
                                }}
                            />
                        </div>
                    </View>
                </FBItemV>
                {/* <FBItemV colspan={24}>
                    <h3 style={{textAlign:'center', padding:15}}>{BP?.title}</h3>
                </FBItemV> */}
                {props?.feedbackLoading ? 
                    <LogoSpinnerV uri={'https://i.ibb.co/bWBCqc6/we-do-verify-icon.png'} />: _getFeedbacks()
                }
               
            </FBGridV>
        )
    }
    let _products = Array.isArray(props?.productsAndBatches) && props?.productsAndBatches.map(item=>{
        return({
            key:item?.gtin,
            label: item?.name,
            value: item?.gtin
        });
    });
    const _navLeftItems = Array.isArray(BP?.navbarLeftItems) && BP?.navbarLeftItems.map(item=>{
        if(item?.appearance ==='drop-down'){
            return({
                ...item,
                dropdownItems:_products
            })
        }
        else return({
            ...item
        })
        
    });
    return(
        <View style={StyleSheet.flatten([styles.bodyContainerStyle])}>
        <BodyTemplateV
        primaryColor={BP?.primaryColor || theme?.palette.primary}
        primaryHighlightColor={BP?.primaryHighlightColor || theme?.palette.secondary}
        secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
        ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
        renderContent={_getData}
        // sidebarStyle={StyleSheet.flatten([styles.sidebarStyle])}
        contentScrollableInline={true}
        headerTitleStyle={{ marginLeft: 20 }}
        navbarStyle={{}}
        layout={'sidebar-header-content-footer'}
        // navBarLeftItems={_navLeftItems}
        // navBarRightItems={BP?.navbarRightItems}
        onNavItemSelect={onNavItemSelect}
        // sideBarItems={BP?.nav_items}
        // onSideNavItemSelect={(eventKey) => setNavItem(eventKey)}
        // renderFooter={() => <div />}
        // sidebarFixedWidth={80}
      />
      <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
        />
        <Modal show={show} onHide={onModalClose} style={StyleSheet.flatten([styles.modalStyle])}>
                <Modal.Header >
                    {/* {_getModalHeader()} */}
                </Modal.Header>
                <Modal.Body style={{ height: 480 }}>

                    {_getModalBody()}
                </Modal.Body>
                <Modal.Footer>
                    {/* {_getModalFooter()} */}
                </Modal.Footer>
        </Modal>
    </View>
    )
}