import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { RatingInfoLG } from './RatingInfo.lg';
import {RatingInfoSM} from './RatingInfo.sm';

export interface RatingInfoProps {
    formData?(formData: any): void;
    onFeedbackSubmit?(formData: any): void;
}

export const RatingInfo = (props: RatingInfoProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <RatingInfoSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    // if(bp === 'lg')
    return <RatingInfoLG {...props} />;
};
