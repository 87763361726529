import React, {ReactNode} from 'react';
import {ButtonV} from 'react-button-rs';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {ImageBackgroundV} from 'react-image-background';
import {StyleSheet, View, Text} from 'react-native';
import {TextV} from 'react-native-text-vfw';
import ReactPlayer from 'react-player';
// import {PricingTableV} from 'react-pricing-table';
import {useViewport, ViewportProvider} from 'react-viewport-provider';
import {Modal} from 'rsuite';
import {Benefits} from './components/Benefits';
import {BodyContent} from './components/BodyContent';
import {FooterV} from './components/FooterV';
import {HeaderV} from './components/HeaderV';
import {IllustratorV, TRenderIllustratorProps} from './components/IllustratorV';
import {PricingTableV} from './components/PricingTable';
import {TableV} from './components/TableV';
const TITLE_FONT_COLOR = '#373A3B';

type BenefitsProps = {
  benefitsTitle?: string;
  benefitsDesciption?: string;
  benefitsData?: any[];
};

export interface LandingPageProps {
  bgImg?: string;
  bgImgHeight?: string;
  title?: string;
  headerContainerStyle?:any;
  titleStyle?:any;
  leftAvatar?: string;
  headerMenu?: any[];
  loginButtonId?: string;
  loginButtonTitle?: string;
  loginButtonStyle?: any;
  loginButtonColor?: string;
  loginButtonTitleStyle?: any;
  onLoginButtonPress?(buttonId: any): void;
  signupButtonId?: string;
  signupButtonTitle?: string;
  signupButtonStyle?: any;
  signupButtonColor?: string;
  signupButtonTitleStyle?: any;
  onSignupButtonPress?(buttonId: any): void;
  videoLink?: string;
  videoDescription?: string;
  videoTitle?: string;
  benefits?: BenefitsProps;
  pricingData?: any[];
  renderIllustrator(props: TRenderIllustratorProps): ReactNode | null;
  illustratorsGroup?: any;
  mobileillustratorsGroup?:any;
  bodyContent?: any;
  onFooterNavSelect?(eventKey?: string): void;
  contactDetails?: {title?: string; details?: any[]};
  socialLinks?: {title?: string; details?: any[]};
  copyright?: {logo?: string; footerNav?: any[]; copyRightText: any};
  parallaxBg?: string;
  mobileParallaxBg?: string;
  pricingDetailData?: {tableHeader?: any[]; listData?: any[]};
  pricingTitle?: string;
  pricingSubTitle?: string;
  themeColor?:string;
  videoButtonColor?:
    | 'red'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'cyan'
    | 'blue'
    | 'violet';
}

export const LandingPageV = (props: LandingPageProps) => {
  const [show, setShow] = React.useState(false);
  const {bp} = useViewport();

  const styles = {
    videoSectionStyle: {
      display: 'flex',
      paddingLeft: bp !== 'xs' && bp !== 'sm' ? '5%' : '1%',
      paddingRight: bp !== 'xs' && bp !== 'sm' ? '5%' : '1%',
      justifyContent: 'center',
      textAlign: 'center',
      paddingBottom: bp !== 'xs' && bp !== 'sm' ? 40 : 10,
      paddingTop: bp !== 'xs' && bp !== 'sm' ? 40 : 10,
    },
    vTitleStyle: {
      fontWeight: 'bold',
      color: TITLE_FONT_COLOR,
      fontSize: bp !== 'xs' && bp !== 'sm' ? 24 : 20,
      lineHeight:26,
      // lineHeight: bp !== 'xs' && bp !== 'sm' ? 30 : 35,
      marginBottom: bp !== 'xs' && bp !== 'sm' ? 30 : 25,
      alignSelf: 'center',
      textAlign: 'center',
      letterSpacing: 2,
    },
    vDescriptionStyle: {
      color: 'rgba(99,102,105,1)',
      display:'block',
      lineHeight:20,
      // lineHeight: bp !== 'xs' && bp !== 'sm' ? 120 : 10,
      alignSelf: 'center',
      fontSize: bp !== 'xs' && bp !== 'sm' ? 18 : 16,
      textAlign: 'center',
      letterSpacing: 2,
      marginTop: bp !== 'xs' && bp !== 'sm' ? 10 : 2,
      marginBottom: bp !== 'xs' && bp !== 'sm' ? 30 : 25,
      paddingLeft: 5,
      paddingRight: 5,
    },
    benefitsContainerStyle: {
      paddingLeft: '5%',
      paddingRight: '5%',
      justifyContent: 'space-around',
      alignItems: 'center',
      paddingTop: 100,
      paddingBottom: 80,
      backgroundColor: '#F0F0F0',
    },
    benefitsTitleStyle: {
      fontSize: 38,
      marginBottom: 16,
    },
    benefitsDescStyle: {
      fontSize: 18,
      marginBottom: 36,
    },
    benefitsCardTitleStyle: {
      fontSize: 18,
      textAlign: 'center',
      fontWeight: 600,
      textTransform: 'uppercase',
      marginBottom: 20,
    },
    amountStyle: {
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'normal',
      color: 'white',
      fontSize: '35px',
      marginTop: '25px',
      marginBottom: '20px',
    },
    sectionOddStyle: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '5%',
      paddingRight: '5%',
      justifyContent: 'space-around',
      alignItems: 'center',
      paddingTop: 100,
      paddingBottom: 80,
      backgroundColor: '#f0f0f0',
    },
    sectionEvenStyle: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '5%',
      paddingRight: '5%',
      justifyContent: 'space-around',
      alignItems: 'center',
      paddingTop: 100,
      paddingBottom: 80,
    },
    sectionTitleStyle: {
      fontSize: 37,
      marginBottom: 25,
    },
    buttonStyle: {
      fontSize: '20px',
      marginBottom: '30px',
      borderRadius: '25px',
      paddingRight: '10px',
      paddingLeft: '10px',
      paddingTop: '5px',
      paddingBottom: '5px',
      color: '#008F69',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    cellStyle: {
      backgroundColor: 'lightgrey',
      color: 'white',
      fontsize: '14px',
      width: '75%',
    },
    rowStyle: {
      width: '75%',
    },
    containerStyle: {
      marginTop: 80,
      marginBottom: 20,
    },
    modalStyle: {
      width: (bp === 'xs') ? '100%' : '60%',
      alignSelf: 'center',
      alignItems: 'center',
      margin: '4% auto 0 auto',
      background: 'transparent'
    },
    modalBodyStyle: {
      height: bp === 'xs' ? 480 : 480
    }
  };
  const _getVideoBlock = () => {
    if (props?.videoLink) {
      return (
        <FBGridV justify={'center'}>
          <FBItemV colspan={22}>
            <View style={StyleSheet.flatten([styles.videoSectionStyle])}>
              <Text
                style={StyleSheet.flatten([styles.vTitleStyle])}
              >
                {props?.videoTitle}
              </Text>
              <Text
                style={StyleSheet.flatten([styles.vDescriptionStyle])}
              >
                {props?.videoDescription}
              </Text>
              {props?.videoLink ? (
                <ButtonV
                  buttonTitle={'Watch Video'}
                  leftIcon={[
                    {
                      name: 'play2',
                      // size: '1x',
                      style: {
                        marginRight: 5,
                      },
                    },
                  ]}
                  containerStyle={{width: 150, alignSelf: 'center'}}
                  color={props?.videoButtonColor}
                  buttonTitleStyle={{color: '#fff'}}
                  onPress={() => {
                    setShow(true);
                  }}
                />
              ) : null}
            </View>
          </FBItemV>
        </FBGridV>
      );
    } else return <div />;
  };

  const _getPriceTable = () => {
    if(props?.pricingData) {
      return (
        <View style={StyleSheet.flatten(styles.containerStyle)}>
          <TextV
            type={
              bp === 'xs' || bp === 'sm'
                ? 'title3'
                : bp === 'md'
                ? 'title2'
                : 'title1'
            }
            style={StyleSheet.flatten([styles.vTitleStyle])}
          >
            {props?.pricingTitle}
          </TextV>
          <TextV
            type={
              bp === 'xs' || bp === 'sm'
                ? 'caption'
                : bp === 'md'
                ? 'caption'
                : 'title3'
            }
            style={StyleSheet.flatten([styles.vDescriptionStyle])}
          >
            {props?.pricingSubTitle}
          </TextV>
          <PricingTableV pricingData={props?.pricingData} />
        </View>
      );
    }
    else {return <div />}
    
  };

  const _getBodyContent = () => {
    return (
      <div id="product">
        <BodyContent
          bodyContent={props?.bodyContent}
          parallaxBg={props?.parallaxBg}
          mobileParallaxBg={props?.mobileParallaxBg}
        />
      </div>
    );
  };

  const _getIllustrators = () => {
    if(bp==='xs' || bp=== 'sm'){
      return (
        <IllustratorV
          renderIllustrator={props?.renderIllustrator}
          illustratorsGroup={props?.mobileillustratorsGroup}
          containerStyle={{width: '100%'}}
        />
      );
    }
    else {
      return (
        <IllustratorV
          renderIllustrator={props?.renderIllustrator}
          illustratorsGroup={props?.illustratorsGroup}
          containerStyle={{width: '100%'}}
        />    
    );
    }
  };

  const _getBenefits = () => {
    return (
      <div id="benefits">
        <Benefits benefits={props?.benefits} />
      </div>
    );

    // let _benefits =
    //   Array.isArray(props?.benefits?.benefitsData) &&
    //   props?.benefits?.benefitsData.map(item => {
    //     if (bp === 'xs' || bp === 'sm') {
    //       return (
    //         <FBItemV colspan={22}>
    //           <View>
    //             <AvatarV
    //               source={{uri: item?.icon}}
    //               avatarStyle={{width: 80, height: 80, resizeMode: 'contain'}}
    //               containerStyle={{
    //                 width: 80,
    //                 height: 80,
    //                 justifyContent: 'center',
    //                 alignSelf: 'center',
    //                 marginBottom: 22,
    //               }}
    //             />
    //             <Text
    //               style={StyleSheet.flatten([styles.benefitsCardTitleStyle])}
    //             >
    //               {item?.title}
    //             </Text>
    //             <Text style={{fontSize: 16, textAlign: 'center'}}>
    //               {item?.desciption}
    //             </Text>
    //           </View>
    //         </FBItemV>
    //       );
    //     } else {
    //       return (
    //         <>
    //           <FBItemV colspan={5}>
    //             <View>
    //               <AvatarV
    //                 source={{uri: item?.icon}}
    //                 avatarStyle={{width: 80, height: 80, resizeMode: 'contain'}}
    //                 containerStyle={{
    //                   width: 80,
    //                   height: 80,
    //                   justifyContent: 'center',
    //                   alignSelf: 'center',
    //                   marginBottom: 22,
    //                 }}
    //               />
    //               <Text
    //                 style={StyleSheet.flatten([styles.benefitsCardTitleStyle])}
    //               >
    //                 {item?.title}
    //               </Text>
    //               <Text style={{fontSize: 16, textAlign: 'center'}}>
    //                 {item?.desciption}
    //               </Text>
    //             </View>
    //           </FBItemV>
    //         </>
    //       );
    //     }
    //   });
    // return (
    //   <FBItemV colspan={24}>
    //     <View style={StyleSheet.flatten([styles.benefitsContainerStyle])}>
    //       <Text style={StyleSheet.flatten([styles.benefitsTitleStyle])}>
    //         {props?.benefits?.benefitsTitle}
    //       </Text>
    //       <Text style={StyleSheet.flatten([styles.benefitsDescStyle])}>
    //         {props?.benefits?.benefitsDesciption}
    //       </Text>
    //       <FBGridV justify={'space-around'}>{_benefits}</FBGridV>
    //     </View>
    //   </FBItemV>
    // );
  };

  const _getPricingDetails = () => {
    if(props?.pricingData) {
      return (
        <TableV
          cellStyle={StyleSheet.flatten([styles.cellStyle])}
          rowStyle={StyleSheet.flatten([styles.rowStyle])}
          data={props?.pricingDetailData}
        />
      );
    }
    else {return <div />}
    
  };

  const onModalClose = () => {
    if (show) {
      setShow(false);
    }
  };

  const _getModalHeader = () => {
    return <div />;
  };

  const _getModalBody = () => {
    return (
      <ReactPlayer
        url={props?.videoLink}
        config={{
          youtube: {
            playerlets: {showinfo: 1},
          },
        }}
        controls={true}
        playing={true}
        width="100%"
        height="100%"
        
      />
    );
  };
  const _getModalFooter = () => {
    return <div />;
  };

  const _renderBody = () => {
    return (
      <ViewportProvider>
        <FBGridV>
          <FBItemV colspan={24}>
            <ImageBackgroundV
              image={props?.bgImg}
              height={props?.bgImgHeight || '80vh'}
              width={'100%'}
              imageBackgroundStyle={{backgroundAttachment: 'fixed'}}
            >
              <HeaderV {...props} />
              {_getVideoBlock()}
              {_getIllustrators()}
            </ImageBackgroundV>
          </FBItemV>
        </FBGridV>
        {/* <FBItemV colspan={24}>
          <Parallax
            blur={{min: -15, max: 15}}
            bgImage={props?.parallaxBg}
            bgImageAlt={props?.bodyContent?.title}
            strength={-100}
          ></Parallax>
        </FBItemV> */}
        {_getBodyContent()}
        <FBGridV justify={'space-around'}>{_getBenefits()}</FBGridV>
        <div id="pricing">
          {_getPriceTable()}
          {_getPricingDetails()}
        </div>
        <div id="contact">
        <FooterV {...props} 
        
        />
        </div>
        
      </ViewportProvider>
    );
  };
  return (
    <>
    <ViewportProvider>
      {_renderBody()}
      </ViewportProvider>
      <Modal
        size={bp==='xs' ? 'xs' : 'sm'}
        show={show}
        onHide={onModalClose}
        style={StyleSheet.flatten([styles.modalStyle])}
      >
        <Modal.Header>{_getModalHeader()}</Modal.Header>
        <Modal.Body style={StyleSheet.flatten([styles.modalBodyStyle])}>{_getModalBody()}</Modal.Body>
        <Modal.Footer>{_getModalFooter()}</Modal.Footer>
      </Modal>
    </>
  );
};
