import React from 'react';
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {TextV} from 'react-native-text-vfw';

export interface FeedbackProductCardProps {
  containerStyle?: ViewStyle;
  cardBgColor?: string;
  imageUri?: ImageSourcePropType;
  title?: string;
  titleColor?: string;
  titleStyle?: TextStyle;
  subTitle?: string;
  subTitleStyle?: TextStyle;
  subTitleColor?: string;
  subTitle1?: string;
  subTitle1Style?: TextStyle;
}

export const FeedbackProductCard = ({
  containerStyle,
  cardBgColor,
  imageUri,
  title,
  titleColor,
  titleStyle,
  subTitle,
  subTitleStyle,
  subTitleColor,
  subTitle1,
  subTitle1Style,
}: FeedbackProductCardProps): JSX.Element => {
  
  const _renderImage = () => {
    if (imageUri) {
      return (
        <View style={StyleSheet.flatten([styles.imgContainer])}>
          <Image
            source={imageUri}
            style={styles.imgStyle}
            resizeMode={'contain'}
          />
        </View>
      );
    }
  };

  const _renderText = () => {
    if (title && typeof title === 'string') {
      return (
        <View style={StyleSheet.flatten([styles.textContainer])}>
          <TextV
            type={'title3'}
            children={title}
            ellipsizeMode={'clip'}
            numberOfLines={2}
            style={StyleSheet.flatten([
              styles.titleStyle,
              titleStyle && titleStyle,
              titleColor && {color: titleColor},
            ])}
          />
          <TextV
            type={'headline'}
            children={subTitle}
            ellipsizeMode={'tail'}
            numberOfLines={2}
            style={StyleSheet.flatten([
              styles.subTitleStyle,
              subTitleStyle && subTitleStyle,
              subTitleColor && {color: subTitleColor},
            ])}
          />
          <TextV
            type={'headline'}
            children={subTitle1}
            ellipsizeMode={'tail'}
            numberOfLines={1}
            style={StyleSheet.flatten([
              styles.subTitle1Style,
              subTitle1Style && subTitle1Style,
              subTitleColor && {color: subTitleColor},
            ])}
          />
        </View>
      );
    }
  };

  return (
    <View
      //@ts-ignore
      style={StyleSheet.flatten([
        styles.container,
        containerStyle,
        cardBgColor && {backgroundColor: cardBgColor},
      ])}
    >
      {_renderImage()}
      {_renderText()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '90%',
    height: '13%',
    borderRadius: 20,
    elevation: 10,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.18,
    shadowRadius: 2,
    padding: 5,
    marginVertical: 20,
    flexDirection: 'row',
  },
  imgContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
    height: '20%',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'transparent',
    elevation: 4,
  },
  imgStyle: {
    width: 100,
    height: 110,
    alignSelf: 'center',
  },
  textContainer: {
    // flexDirection: 'column',
    width:'75%',
    marginLeft: 10,
    padding:5
  },
  titleStyle: {
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'capitalize',
    paddingTop: 2,
    fontWeight: '500',
    width: '100%',
  },
  subTitleStyle: {
    fontSize: 14,
    textAlign: 'left',
    paddingVertical: 6,
  },
  subTitle1Style: {
    fontSize: 14,
    textAlign: 'left',
  },
});

FeedbackProductCard.defaultProps = {
  titleColor: '#F05776',
  subTitleColor: '#999999',
} as Partial<FeedbackProductCardProps>;
