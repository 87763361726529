import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { ProductInfoScreenSM } from './ProductInfoScreen.sm';
import { ProductInfoScreenMD } from './ProductInfoScreen.md';
import { ProductInfoScreenLG } from './ProductInfoScreen.lg';

export interface ProductInfoScreenProps {
    onFabClick?(): void;
    headerTitle?: string;
    headerTitleStyle?: object;
    onInfoClick?(): void;
    handleLGMarkerClick?(item: any): void;
    handleTimelineLGClick?(item: any): void;
    onClickData?: any;
    googleMapAPIKey?:string;
}

export const ProductInfoScreen = (props: ProductInfoScreenProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <ProductInfoScreenSM {...props} />;
    }

    if (bp === 'md') {
      return <ProductInfoScreenMD {...props} />;
    }

    if(bp === 'lg')
    return <ProductInfoScreenLG {...props} />;
};
