import React, {useContext} from 'react';
import {StyleSheet} from 'react-native';
import config from '../../../config/default-blueprint.json';
import {ThemeContext} from '../../../../../providers/theme';
import {useViewport} from 'react-viewport-provider';
import {OrganizationProfileV} from 'react-organisation-profile';

const BP = config.dashboard;
const EXPANDABLE_WIDTH = 256;

interface IProps {
  orgProfileData?:any;
}

export const OrganizationProfile = (props:IProps) => {
  const theme = useContext(ThemeContext);
  const {width, height} = useViewport();
  const styles = StyleSheet.create({
    imageBackgroundstyle: {
        backgroundColor: theme?.palette.ternary,
    }
  });
  
  const handleHeaderEditButtonPress = () => {

  }
  
  return (
    <OrganizationProfileV
      width={'100%'}
      height={0.9 * height}
      image={props?.orgProfileData?.image}
      title={props?.orgProfileData?.title}
      subtitle={props?.orgProfileData?.subtitle}
      leftAvatar={props?.orgProfileData?.leftAvatar}
      headerButtonId={props?.orgProfileData?.headerButtonId}
      headerButtonTitle={props?.orgProfileData?.headerButtonTitle}
      headerButtonColor={theme?.palette.secondary}
      onHeaderButtonPress={handleHeaderEditButtonPress}
      profileDetails={props?.orgProfileData?.profileDetails}
      imageBackgroundStyle={StyleSheet.flatten([styles.imageBackgroundstyle])}
    />
  );
};
