import React, { useContext, useEffect } from 'react';
import config from '../../../config/default-blueprint.json';
import {ThemeContext} from '../../../../../providers/theme';
import {useViewport} from 'react-viewport-provider';
import {ProfileTemplateV} from 'react-profile-template-1';
import {useAuth} from '../../../../../services/auth-service/auth.provider';
import { useHistory, useLocation, Prompt } from 'react-router-dom';

const BP = config.dashboard;
interface Iprops {
  onSignOut?:void;
  userProfile?:any;
};

export const ProfileTemplate = (props:Iprops) => {
    const theme = useContext(ThemeContext);
    const { width, height } = useViewport();
    const{signOut, getUserProfile} = useAuth();
    const history = useHistory();
    const handleEditProfilePress = () => {};

    const handleSignOutPress = () => {
      signOut();
      history.push('/')
    };

    let _vidtemp = props?.userProfile?.did;

    let _vid = _vidtemp && _vidtemp?.substr(0, 16)+"..."+ _vidtemp?.substr(_vidtemp?.length - 4);
    let _profileDetails = Array.isArray(BP?.profileTemplate?.profileDetails) && BP?.profileTemplate?.profileDetails.map(item=>{
      if(item.eventKey === 'fullName'){
        return({
          ...item,
          title: props?.userProfile?.fullName
        });
        
      }
      else if(item.eventKey=== 'email'){
          return({
            ...item,
            title: props?.userProfile?.email
          });
      }
      else if(item.eventKey === 'phone'){
        return({
          ...item,
          title: props?.userProfile?.telephone && props?.userProfile?.telephone
        });
      }
      else {
        return({
          ...item
        })
      }
    })

    return (
      <ProfileTemplateV
        backgroundImage={BP.profileTemplate?.backgroundImage}
        fullName={props?.userProfile?.fullName ?? BP.profileTemplate?.fullName}
        nickName={props?.userProfile?.fullName ?? BP.profileTemplate?.nickName}
        email={props?.userProfile?.email ?? BP.profileTemplate?.email}
        vid={_vid || BP.profileTemplate?.vid}
        vidTooltip={BP.profileTemplate?.vidTooltip}
        // editTitle={BP.profileTemplate?.editTitle}
        signOutTitle={BP.profileTemplate?.signOutTitle}
        buttonTitleColor={theme?.palette.secondary}
        signOutIcon={{
          name: BP.profileTemplate?.signOutIconName,
          style: {
            color: theme?.palette.secondary,
          },
        }}
        editIcon={{
          name: BP.profileTemplate?.editIconName,
          style: {
            color: theme?.palette.secondary,
          },
        }}
        onEditPress={handleEditProfilePress}
        onSignOutPress={handleSignOutPress}
        profileDetails={_profileDetails}
      />
    );
}